import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";

const GenderPlacementStats = () => {

  const { studentPlacedByGender } = useSelector((state) => state.studentPlacedByGender)

  
  const chartData = {
    labels: studentPlacedByGender.map((student) => student.gender),
    datasets: [
      {
        label: "",
        data: studentPlacedByGender.map((student) => student.placed),
        backgroundColor: ["#FDE3DF", "#CA498C"],
      },
    ],
  };
  return (
    <div>
      <div className="rounded-lg shadow-lg px-4 py-2">
        <h2 className="text-primaryTextColor text-lg font-semibold">Gender Wise Placement Statistics</h2>
        <Pie data={chartData}></Pie>
      </div>
    </div>
  )
}
export default GenderPlacementStats