import { Link } from "react-router-dom";
import resume from "../../images/Resume_Ritik_Jha.pdf";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { Document, Page } from 'react-pdf';
import { DownloadIcon, Eye, Trash2 } from "lucide-react";
import DocumentCard from "./DocumentCard";
import { fetchStudentDocs } from "../../redux/features/getStudentDocsSlice";
import { useEffect } from "react";


const StudentDocuments = ({id}) => {

  const { documents, loading } = useSelector((state) => state.studentDocuments)
  const dispatch = useDispatch();
  useEffect(() => {
    //function call to fetch the student by the id
    dispatch(fetchStudentDocs(id))
  }, [id]);

  return (
    <div className="grid grid-cols-3 gap-x-6">
      <div className="rounded-lg border-2 shadow-md px-6 py-4">
        <h1>Marksheet</h1>
        <div className="mt-2 flex flex-col space-y-4">
          {
            documents.filter((document) => document?.label === "Marksheet").map((document) =>
              <DocumentCard document={document} />)
          }
        </div>

      </div>
      <div className="rounded-lg border-2 shadow-md px-6 py-4">
        <h1>Certificates</h1>
        <div className="mt-2 flex flex-col space-y-4">
          {
            documents.filter((document) => document?.label === "Certifications").map((document) =>
              <DocumentCard document={document} />)
          }
        </div>
      </div>
      <div className="rounded-lg border-2 shadow-md px-6 py-4">
        <h1>Other Documents</h1>
        <div className="mt-2 flex flex-col space-y-4">
          {
            documents.filter((document) => document?.label === "Resume").map((document) =>
                <DocumentCard document={document} />)
          }
        </div>
      </div>
    </div>
  );
};
export default StudentDocuments;
