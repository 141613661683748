import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { fetchJobFormDetail } from "../../redux/features/jobFormDetailSlice"
import { useEffect, useState } from "react"
import { JobDetailHeader } from "./JobDetailHeader"
import KeyValuedetails from "../students/KeyValuedetails"
import { Table, TableCell, TableHead, TableRow, TableHeader, TableBody } from "../ui/table"
import { Skeleton } from "../ui/skeleton"
import { DataTable } from "../reusable/table/DataTable"
import { TargetPoolColumns } from "./JobTable/TargetPoolColumns"

const JobDetail = () => {

  const { job, loading } = useSelector((state) => state.jobFormDetailReducer)

  const { jobId } = useParams()
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchJobFormDetail(jobId))
  }, [jobId])
  if (loading || !job) {
    return <div style={{ margin: "6px" }}>
      <div className="grid grid-cols-3 space-x-6 h-16">
        <div className="bg-cardBgColor/35 relative col-span-3 py-4 px-6 shadow-lg rounded-lg border h-full">
          <Skeleton className={"w-full h-full"} />
        </div>
      </div>
      <div className="grid grid-rows-1 grid-cols-8 gap-x-10 my-6 grid-flow-col gap-y-4 h-40">
        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-1 col-span-2">
          <Skeleton className={"w-full h-full"} />
        </div>
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-1 col-span-2">
          <Skeleton className={"w-full h-full"} />
        </div>

        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-1 col-span-4">
          <Skeleton className={"w-full h-full"} />
        </div>
      </div>
      <div className="grid grid-cols-6 gap-x-10 my-0 grid-flow-col gap-y-4 h-80">
        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-3 col-span-2">
          <Skeleton className={"w-full h-full"} />
        </div>
        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-3 col-span-4">
          <Skeleton className={"w-full h-full"} />
        </div>
      </div>
    </div>
  }
  return (
    <div className="px-4 mt-3">
      <JobDetailHeader data={job} />
      <div className="grid grid-rows-1 grid-cols-8 gap-x-10 my-6 grid-flow-col gap-y-4">
        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-1 col-span-2">
          <h2 className="text-primaryButtonColor font-bold text-lg">Point of Contact</h2>
          <KeyValuedetails title={"Name"} value={job.contact_name ? job.contact_name : "-"} />
          <KeyValuedetails title={"Email"} value={job.contact_email ?
            <a className="underline" href={`mailto:${job.contact_email}`}>{job.contact_email}</a>
            : "-"} />
          <KeyValuedetails title={"Phone"} value={job.contact_phone_number ? job.contact_phone_number : "-"} />
        </div>
        <div className="rounded-lg border-2 shadow-lg px-6 py-4 row-span-1 col-span-2">
          <h2 className="text-primaryButtonColor font-bold text-lg">Company Details</h2>
          <KeyValuedetails title={"Sector"} value={job.sector ? job.sector : "-"} />
          <KeyValuedetails title={"Annual Turnover"} value={job.annual_turnover ?
            new Intl.NumberFormat(navigator.language).format(job.annual_turnover)
            : "-"} />
          <KeyValuedetails title={"Address"} value={job.address ? job.address : "-"} />
        </div>

        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-1 col-span-4">
          <h2 className="text-primaryButtonColor font-bold text-lg">Opening Details</h2>
          <div className="grid grid-cols-2 gap-x-10">
            <KeyValuedetails title={"Job Profile"} value={job.job_profile ? job.job_profile : "-"} />
            <KeyValuedetails title={"Designation"} value={job.designation ? job.designation : "-"} />
            <KeyValuedetails title={"CTC offered"} value={job.ctc_offered ?
              new Intl.NumberFormat(navigator.language).format(job.ctc_offered) + "/year" : "-"} />
            <KeyValuedetails title={"Branch"} value={job.branch ? job.branch : "-"} />
            <KeyValuedetails title={"Preffered Mode"} value={job.mode_of_recruitment ? job.mode_of_recruitment : "-"} />
            <KeyValuedetails title={"Location of posting"} value={job.location_of_posting ? job.location_of_posting : "-"} />
          </div>

        </div>
      </div>
      <div className="grid grid-cols-6 gap-x-10 my-0 grid-flow-col gap-y-4">
        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-3 col-span-2">
          <h2 className="text-primaryButtonColor font-bold text-lg">Selection Procedure</h2>
          <div className="flex items-center flex-col space-y-3 mt-3">
            {job.selection_procedure.map((round, i) => <div key={i} className="rounded-xl border shadow-md  px-4 py-1 text-md font-bold w-full">
              <p>{round}</p>
            </div>)}
          </div>
        </div>
        <div className="rounded-lg border-2 shadow-lg px-6 py-4  row-span-3 col-span-4">
          <h2 className="text-primaryButtonColor font-bold text-lg">Target Pool</h2>
          {job.target_pool && <DataTable hasPagination={false} hasClick={false} columns={TargetPoolColumns} data={job.target_pool} />}
        </div>
      </div>
    </div>

  )
}
export default JobDetail