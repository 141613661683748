import { Box } from "@mui/material";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { Chart as ChartJS, Legend } from "chart.js";
import { useSelector } from "react-redux";

ChartJS.register(Legend);
const ChartsSection = ({ company, student }) => {

  const { studentRegistered } = useSelector((state) => state.studentPlaced)
  const { companyVisitedCountByBranch } = useSelector((state) => state.companyVisited)
  const { studentPlacedByGender } = useSelector((state) => state.studentPlacedByGender)
  const { placedStudentsPerCompany } = useSelector((state) => state.selectedCompanyStudentCount)
  const { ctcOfferedData } = useSelector((state) => state.ctcOffered)
  const { percentageWisePlaced } = useSelector((state) => state.percentageWisePlaced)
  const { newRecurringCount } = useSelector((state) => state.newRecurring)
  const barChartData1 = {
    labels: company ? companyVisitedCountByBranch?.map((company) => company?.branch?.split(' ')[0]) : studentRegistered.map((student) => student.branch.split(' ')[0]),
    datasets: [
      {
        label: company ? "Companies Visited" : student ? "Students registered" : "Students Placed",
        data: company ? companyVisitedCountByBranch?.map((company) => company.count) : student ? studentRegistered.map((student) => student.registered) : studentRegistered.map((student) => student.placed),
        backgroundColor: ["#CBB6F8"],
        width: "20px",
      },
    ],
  };

  const optionsBarChart1 = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: company ? "No. of Companies" : student ? "No. of students registered" : 'No. of students placed',
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: company ? "Branches" : "Branch",
        },
      },
    },
  };
  const barChartData2 = {
    labels: company ? placedStudentsPerCompany?.map((company) => company.companyName) : ctcOfferedData.map((offer) => offer.companyName),
    datasets: [
      {
        label: company
          ? "Students Placed per Company"
          : "CTC offered(in lakhs)",
        data: company ? placedStudentsPerCompany?.map((student) => student.studentCount) : ctcOfferedData.map((offer) => offer.ctcOffered),
        backgroundColor: ["#A2DB00"],
      },
    ],
  };

  const optionsBarChart2 = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: company ? "Students" : "CTC offered in (in lakhs)",
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Companies",
        },
      },
    },
  };
  const pieChartData1 = {
    labels: company
      ? ["North", "East", "West", "South"]
      : percentageWisePlaced.map((company) => company.companyName),
    datasets: [
      {
        label: company ? "Companies" : "Percentage",
        data: company ? [8, 5, 3, 4] : percentageWisePlaced.map((company) => company.percentage),
        backgroundColor: company
          ? ["#5E3FBE", "#F4F0FD", "#E5DAFB", "#CBB6FD"]
          : ["#FFD76E", "#E37C7C", "#9F629A", "#FF9C08"],
      },
    ],
  };

  const pieChartData2 = {
    labels: company ? ["New", "Recurring"] : studentPlacedByGender.map((student) => student.gender),
    datasets: [
      {
        label: company ? "Companies" : "",
        data: company ? [newRecurringCount?.new,newRecurringCount?.recurring] : student ? studentPlacedByGender.map((student) => student.registered) : studentPlacedByGender.map((student) => student.placed),
        backgroundColor: company
          ? ["#C9EFD0", "#67C587"]
          : ["#FDE3DF", "#CA498C"],
      },
    ],
  };

  return (
    <>
      {company ? (
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}
          mt={2}
        >
          <BarChart chartData={barChartData1} options={optionsBarChart1} />
          <BarChart chartData={barChartData2} options={optionsBarChart2} />
          <PieChart
            chartData={pieChartData1}
            head={"Location-Wise companies visited"}
          />
          <PieChart
            chartData={pieChartData2}
            head={"New v/s Recurring company"}
          />
        </Box>
      ) : !student ? (
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}
          mt={2}
        >
          <PieChart chartData={pieChartData2} head={"Gender-wise placements"} />
          <PieChart chartData={pieChartData1} head={"Company-wise % placed"} />

          <BarChart chartData={barChartData1} options={optionsBarChart1} />
          <BarChart chartData={barChartData2} options={optionsBarChart2} />
        </Box>
      ) : (
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}
          mt={2}
        >
          <BarChart student chartData={barChartData1} options={optionsBarChart1} />
          <PieChart student chartData={pieChartData2} head={"Gender-wise Registered"} />
        </Box>
      )}
    </>
  );
};
export default ChartsSection;
