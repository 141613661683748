import CustomImage from "../../CustomImage";

export const PlacementStatsColumns = [
    {
        accessorKey: "company.logoUrl",
        header: "Logo",
        cell: ({ row }) => {
            const company = row.original;
            return  <CustomImage className="w-12 h-12 object-cover rounded-full" src={company.logoUrl} alt="" section="company"  />
        }
    },
    {
        accessorKey: "company.name",
        header: "Name",
    },
    {
        accessorKey: "placementStatus",
        header: "Placement Status",
    },
    {
        accessorKey: "round.name",
        header: "Round Name",
    },
]