
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/logo.png";
import profilePic from "../assets/profilePic.png";
import { useDispatch, useSelector } from "react-redux";
import { open } from "../redux/features/menuSlice";
import { auth } from "../config/firebaseConfig";
import { logoutUser } from "../redux/features/loginSlice";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui/dropdown-menu";
import CustomImage from "./CustomImage";

const Navbar = ({ home }) => {

  const { user } = useSelector((state) => state.loginReducer)
  const isMobile = useMediaQuery({
    query: "(max-width: 590px)",
  });

  const { openMenu } = useSelector((state) => state.openMenu)
  const { pathname } = useLocation();
  let sidebarLink = pathname.split("/")[1]
  const userName = user?.email.split("@");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout = async () => {
    dispatch(logoutUser())
    await auth.signOut();
  };

  return (
    <nav className="bg-white z-10 h-16 flex items-center fixed top-0 left-8 right-8">
      <div className="flex items-center w-full justify-between">
        <div className="flex items-center gap-x-2">
          <img className="max-w-[120px]" src={logo} alt="" />
          <h1 className="font-bold text-2xl">DASHBOARD</h1>
        </div>
        <div className="flex items-center gap-x-2">
          <div className="flex flex-col items-end">
            <p className="text-lg font-semibold">{user?.name}</p>
            <p className="text-sm font-light">{user?.designation}</p>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <CustomImage className="max-w-[50px] rounded-full" src={(user?.profilePic&&user?.profilePic!=="")?user?.profilePic:profilePic} alt="" section="profile"/>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => navigate("/my-profile")}>My Profile</DropdownMenuItem>
              <DropdownMenuItem onClick={() => navigate("/my-team")}>My Team</DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleLogout()}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

        </div>
      </div>

    </nav>
  );
};
export default Navbar;
