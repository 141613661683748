import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getDriveDropdown = createAsyncThunk('getDriveDropdown', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/drive/drive-dropdown-items`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const driveDropdownSlice = createSlice({
    name: 'Drive Dropdown',
    initialState: {
        loading: false,
        driveDropdown: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getDriveDropdown.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getDriveDropdown.fulfilled, (state, action) => {
            state.loading = false;
            state.driveDropdown = action.payload
        })
        builder.addCase(getDriveDropdown.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default driveDropdownSlice.reducer