import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const startDrive = createAsyncThunk('startDrive', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/job/start-drive/${driveId}`);
        return data
    } catch (error) {
        console.log(rejectWithValue(error))
        return rejectWithValue(error)
    }
})

export const startDriveSlice = createSlice({
    name: 'Start Drive Slice',
    initialState: {
        loading: false,
        error: null,
        success: null,
        message: null,
    },
    reducers: {
        resetStartDriveSuccess: (state) => {
            state.success = false
            state.message = null
        },
        resetStartDriveError: (state) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(startDrive.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(startDrive.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.message
            state.success = true;
        })
        builder.addCase(startDrive.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetStartDriveSuccess, resetStartDriveError } = startDriveSlice.actions
export default startDriveSlice.reducer