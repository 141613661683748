import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../components/Hero/Notification";
import UpcomingEvents from "../components/Hero/UpcomingEvents";
import BarChart from "../components/Hero/BarChart";
import InviteCompanies from "../components/Hero/InviteCompanies";
import { fetchStudentRegisteredAndPlaced } from "../redux/features/getStudentRegisterdAndPlacedData";
import { fetchRecentlyConnectedCompanies } from "../redux/features/recentlyConnectedCompaniesSlice";
import { fetchAllStudents } from "../redux/features/studentSlice";
import PlacedStudents from "../components/Hero/PlacedStudents";
import { Calendar } from "../components/ui/calendar";
import { getAllNotifications } from "../redux/features/notificationsSlice";
import moment from "moment";
import { fetchAllEvents } from "../redux/features/eventsReducerSlice";

const Home = () => {
  const dispatch = useDispatch()
  const { recentlyConnected } = useSelector((state) => state.recentlyConnected)
  const { events, loading: eventsLoading } = useSelector((state) => state.eventsReducer)
  const [driveDates, setDriveDates] = useState([]);
  useEffect(() => {
    dispatch(fetchStudentRegisteredAndPlaced())
    dispatch(fetchRecentlyConnectedCompanies())
    dispatch(fetchAllStudents({ type: 'Student', limit: 5, placed: "Placed", offset: 0 }))
    dispatch(getAllNotifications());
    const currentMonthStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const currentMonthEndDate = moment().endOf('month').format('YYYY-MM-DD');
    dispatch(fetchAllEvents({ startDate: currentMonthStartDate, endDate: currentMonthEndDate }))
  }, [])


  useEffect(() => {
    let dates = [];
    Array.isArray(events) && events.forEach((item) => {
      item.startDate && dates.push(item.startDate);
      item.endDate && dates.push(item.endDate);
      item.driveDate && dates.push(item.driveDate);
    })
    setDriveDates(dates);

  }, [events])

  const handleUpdatedMonth = (data) => {
    const startDate = moment(moment(data).startOf('months')).format('YYYY-MM-DD');
    const endDate = moment(moment(data).endOf('months')).format('YYYY-MM-DD');
    dispatch(fetchAllEvents({ startDate, endDate }));
  }

  return (
    <div className="h-full">
      <div className="grid grid-cols-3 grid-rows-6 gap-x-6 gap-y-6 max-h-[1060px] h-full">
        <BarChart />
        <Notification />
        <InviteCompanies title="Recently Connected" companyData={recentlyConnected.connectedCompanies} />
        <div className="col-span-1 row-span-4">
          <PlacedStudents />
        </div>
        <div className="border shadow-lg rounded-2xl px-1 py2 row-span-2 col-span-1 mt-[-50px] p-5">
          <div className="w-full flex items-center justify-center">
            <Calendar className="w-full " loading={eventsLoading} handleUpdatedMonth={handleUpdatedMonth} dates={driveDates} mode="multiple" />
          </div>
        </div>
        <div className="mt-[-50px]">
          <InviteCompanies title="Invite New Companies" companyData={recentlyConnected.invitedCompanies} />
        </div>
        {/* <div style={{ height: '250px' }} className="h-[300px]"> */}
        <UpcomingEvents eventData={events ? events : []} loading={eventsLoading} />
        {/* </div> */}
      </div>
    </div>
  );
};
export default Home;
