import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { useContext } from "react";
import { Bar } from "react-chartjs-2";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import { useSelector } from "react-redux";
import { Button } from "../ui/button";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const BarChart = () => {

  const { studentRegistered } = useSelector((state) => state.studentPlaced)

  const data = {
    labels: studentRegistered?.map((student) => student.year),
    datasets: [
      {
        label: "Total Registered Students",
        data: studentRegistered?.map((student) => student.registeredStudentsCount),
        backgroundColor: "#FEB8B9",
      },
      {
        label: "Total Placed Students",
        data: studentRegistered?.map((student) => student.placedStudentsCount),
        backgroundColor: "#D77275",
      },
    ],
  };
  
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "No. of Students",
          font: {
            weight: 'bold', // Make the y-axis title bold
          },
        },
        ticks: {
          font: {
            weight: 'bold', // Make the y-axis labels bold (no. of students)
          },
          color: 'black', // Set y-axis labels color to black
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Year",
          font: {
            weight: 'bold', // Make the x-axis title bold
          },
          color: 'black',
        },
        ticks: {
          font: {
            weight: 'bold', // Make the x-axis labels bold (years)
          },
          color: 'black', // Set x-axis labels color to black
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 27,
    plugins: {
      legend: {
        labels: {
          font: {
            weight: 'bold', // Make the legend labels bold
          },
          color: 'black', // Set legend labels color to black
        },
      },
    },
  };
  
  

  const navigate = useNavigate()
  return (
    <div className="border rounded-2xl shadow-lg px-4 py-6 row-span-2 col-span-2">
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-primaryTextColor text-xl">Reports</h1>
        <Button onClick={() => navigate("/reports")} variant="primary" className="py-0" size="sm">
          See All
        </Button>
      </div>
      <div className="h-full pb-2">
        <Bar data={data} options={options} />
      </div>
      <Link to={"/reports"}>

      </Link>
    </div>
  );
};
export default BarChart;
