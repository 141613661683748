import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const updateTeamPermissions = createAsyncThunk('updateTeamPermissions', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.patch(`${process.env.REACT_APP_BACKEND_URL}/api/tpo/my-team/${obj.tpoId}`, obj.body)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const managePermissionsSlice = createSlice({
    name: 'managePermission',
    initialState: {
        loading: false,
        success: false,
        error: null
    },
    reducers: {
        resetTeamUpdateSuccess: (state, action) => {
            state.success = false
        },
        resetTeamUpdateError: (state, action) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateTeamPermissions.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateTeamPermissions.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
        })
        builder.addCase(updateTeamPermissions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export const { resetTeamUpdateSuccess,resetTeamUpdateError } = managePermissionsSlice.actions
export default managePermissionsSlice.reducer