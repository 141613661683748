import { Box, Button, Typography } from "@mui/material"
import { AiOutlinePlus } from "react-icons/ai"
import TeamTable from "./TeamTable"
import { useDispatch, useSelector } from "react-redux"
import AddMember from "./AddMember"
import { useNavigate } from "react-router-dom"
import { handleSnackbar } from "../../redux/features/snackbarSlice"
import { IoArrowBackCircle } from "react-icons/io5"
import MemberTable from "./memberTable/MemberTable"
import { memberColumns } from "./memberTable/MemberColumns"
import { fetchAllTeamMembers } from "../../redux/features/getAllTeamMembers"
import { useEffect } from "react"
import CompanyLoading from "../Companies/CompanyLoading"
import { DataTable } from "../reusable/table/DataTable"

const MyTeam = () => {

  const { user } = useSelector((state) => state.loginReducer)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { students, loading } = useSelector((state) => state.getAllMembers)
  const { success, loading: permissionUpdateLoader } = useSelector((state) => state.managePermissions)

  useEffect(() => {
    dispatch(fetchAllTeamMembers())
  }, [success])

  return (
    <div className="mt-3 px-4">
      <div className="bg-cardBgColor/35 relative col-span-2 py-4 px-10 shadow-lg rounded-lg border">
        <div onClick={() => navigate(-1)}>
          <IoArrowBackCircle size={50} className="text-primaryTextColor cursor-pointer hover:text-primaryTextColor/80 absolute -left-5 -top-2" />
        </div>
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">My Team</h1>
          <AddMember onCreation={() => {
            dispatch(fetchAllTeamMembers())
          }} />
        </div>
      </div>
      <div className="mt-6">
        {loading ? <CompanyLoading /> : students?.result && <DataTable hasClick={false} hasPagination={false} columns={memberColumns} data={students?.result} />}
      </div>
    </div>
  )
}
export default MyTeam