import { cn } from "../../../lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";

const CommonSelect = ({ items, defaultValue, hideNone = false, selectPlaceholder, handleSelectChange, className,isOptionDisabled }) => {

    return (
        <div>
            <Select defaultValue={defaultValue} onValueChange={handleSelectChange}>
                <SelectTrigger className={cn("w-[180px]", className)}>
                    <SelectValue placeholder={selectPlaceholder} />
                </SelectTrigger>
                <SelectContent>
                    {
                        !hideNone && <SelectItem value={null}>None</SelectItem>
                    }
                    {
                        items.map((item, i) => {
                            const itemValue = typeof item === "object" ? item.value ?? item : item;
                            const itemTitle = typeof item === "object" ? item.label ?? item : item;
                            return <SelectItem key={i} value={itemValue} disabled={isOptionDisabled ? isOptionDisabled(itemValue) : false}>
                            {itemTitle}
                          </SelectItem>
                        })
                    }
                </SelectContent>
            </Select>

        </div>
    )
}
export default CommonSelect