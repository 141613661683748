import { ChevronLeft, ChevronRight } from "lucide-react"
import CommonSelect from "../dropdown/CommonSelect"
import { Button } from "../../ui/button"

const DataTablePagination = ({ table, data }) => {

    const onPageSizeChange = (value) => {
        table.setPageSize(Number(value));
        table.setPageIndex(0);
    }

    return (
        <div className="flex items-center justify-between mt-6">
            <p>Showing {table.getRowCount()} rows</p>
            <div className="flex items-center gap-x-4">
                <CommonSelect items={[10, 20, 30, 40, 50]} className="w-[100px]" defaultValue={table.getState().pagination.pageSize} handleSelectChange={onPageSizeChange} hideNone={true} selectPlaceholder={""} isOptionDisabled={(size) => size > data.count} />

                <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                    Page {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount()}
                </div>                <div className="flex items-center gap-x-1" >
                    <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} variant="outline" className="px-2 py-4" size="sm">
                        <ChevronLeft className="w-6 h-6" />
                    </Button>
                    <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} variant="outline" className="px-2 py-4" size="sm">
                        <ChevronRight className="w-6 h-6" />
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default DataTablePagination