import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchCorporateNetworkDetails = createAsyncThunk('fetchCorporateNetworkDetails', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/network`, {
            params: {
                sortBy: obj ? obj.sortBy : '',
                name: obj ? obj.name : ''
            }
        })
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const corporateNetworkSlice = createSlice({
    name: 'corporateNetworkSlice',
    initialState: {
        loading: false,
        corporateNetworks: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCorporateNetworkDetails.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchCorporateNetworkDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.corporateNetworks = action.payload
        })
        builder.addCase(fetchCorporateNetworkDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default corporateNetworkSlice.reducer