import { Skeleton } from "./ui/skeleton"

const HomeLoading = () => {
  return (
    <div className="flex flex-col space-y-6 px-6 ">
        <div>
            <Skeleton className="w-full h-16" />
        </div>
        <div className="flex items-center gap-x-6">
            <Skeleton className="w-1/4 h-[88vh]" />
            <Skeleton className="w-3/4 h-[88vh]" />
        </div>
    </div>
  )
}
export default HomeLoading