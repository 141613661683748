import React from "react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "../../ui/table";
import "../../Companies/CompanyTable/CompanyTable.css";
import DataTablePagination from "./DataTablePagination"

export function DataTable({ columns, hasClick, data, onClickRoute, pagination, setPagination, hasPagination = true }) {

    const table = useReactTable({
        data: data.results || data.result || data,
        columns,
        manualPagination: true,
        pageCount: data.count && data.limit ? Math.ceil(data.count / data.limit) : 1,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        getPaginationRowModel: getPaginationRowModel(),
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <div>
            <Table className="data-table">
                <TableHeader className="bg-red-400 rounded-lg">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                data-state={row.getIsSelected() && "selected"}
                                onClick={() => hasClick && onClickRoute(row.original)}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {
                hasPagination &&
                <DataTablePagination table={table} data={data} />
            }
        </div >
    )
}