import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

const CompaniesPlacement = () => {
    const { placedStudentsPerCompany } = useSelector((state) => state.selectedCompanyStudentCount)

    const chartData = {
        labels: placedStudentsPerCompany?.map((company) => company.companyName),
        datasets: [
            {
                label: "Students Placed per Company",
                data: placedStudentsPerCompany?.map((student) => student.studentCount),
                backgroundColor: ["#A2DB00"],
            },
        ],
    };


    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Students",
                },
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Companies",
                },
            },
        },
    };
    return (
        <div>
            <div className="rounded-lg shadow-lg px-4 py-2">
                <h2 className="text-primaryTextColor text-lg font-semibold">Company-wise Placements</h2>
                <Bar data={chartData} options={options}></Bar>
            </div>
        </div>
    )
}
export default CompaniesPlacement