import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Day, DayPicker } from "react-day-picker";
import { useState, useEffect } from "react";
import moment from "moment";
import { cn } from "../../lib/utils";
import { buttonVariants } from "./button";
import { Skeleton } from "./skeleton";

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  handleUpdatedMonth = () => {},
  dates,
  loading = false,
  ...props
}) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const updatedArray = [];
    dates?.forEach((item) => {
      const dateObj = moment(item, "YYYY-MM-DD").toDate();
      updatedArray.push(dateObj);
      setSelected(updatedArray);
    });
  }, [dates]);

  const handleChangeMonth = (data) => {
    handleUpdatedMonth(data);
  };

  return (
    <DayPicker
      showOutsideDays 
      selected={selected}
      onSelect={setSelected}
      onMonthChange={handleChangeMonth}
      className={cn("p-3", className)}
      classNames={{
        months: "w-full flex",
        month: "space-y-4 w-full",
        caption: "w-full flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium text-primaryTextColorLight",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "ghost" }),
          "h-7 w-7 bg-transparent p-0 opacity-100 hover:opacity-50"
        ),
        nav_button_previous: "absolute left-1 text-primaryTextColor",
        nav_button_next: "absolute right-1 text-primaryTextColorLight",
        table: "w-full border-collapse space-y-1",
        head_row: "flex w-full justify-evenly",
        head_cell:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem] text-primaryTextColorLight",
        row: "flex w-full mt-2 justify-evenly",
        cell: "overflow-clip rounded-full h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        tbody: "w-full",
        thead: "w-full",
        day_range_end: "day-range-end",
        day_selected:
          "bg-primaryTextColorLight text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-primaryTextColorLight text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
        Day: ({ ...props }) => {
          if (loading) {
            return <Skeleton className="size-full rounded-full" />;
          }
          return <Day {...props} />;
        },
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
