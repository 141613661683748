import ReportCompany from "./ReportCompany";
import ReportHead from "./ReportHead";
import ReportStudent from "./ReportStudent";
import ReportPlacement from "./ReportPlacement";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompaniesCountByBranch } from "../../redux/features/companiesVisitedCountSlice";
import { fetchStudentRegisteredAndPlaced } from "../../redux/features/getStudentRegisterdAndPlacedData";
import { fetchStudentPlacedByGender } from "../../redux/features/getStudentPlacedViaGender";
import { fetchTotalPlacedStudentsCount } from "../../redux/features/totalPlacedStudentsCountSlice";
import { fetchPlacedCompanyStudentsCount } from "../../redux/features/selectedCompanyStudentsCountSlice";
import { fetchCtcOffered } from "../../redux/features/ctcOfferedSlice";
import { fetchPlacementGraph } from "../../redux/features/placementGraphSlice";
import { fetchCompanyWisePercentagePlaced } from "../../redux/features/percentageWisePlaced";
import { fetchNewVsRecurringCount } from "../../redux/features/newRecurringSlice";
import { fetchTopReports } from "../../redux/features/getReportsSlice";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from "../ui/select";
import { updateSelectedYear } from "../../redux/features/reportsYearFilterSlice";
import ReportCard from "./ReportCard";
import { fetchCompanyDropdown } from "../../redux/features/companyDropdownSlice";
import { getConnectionStatusCount } from "../../redux/features/connectionCountSlice";
import PlacementProgressGraph from "./PlacementProgressGraph";
import CompaniesVisitedByBranch from "./CompaniesVisitedByBranch";
import CompaniesPlacement from "./CompaniesPlacement";
import CtcStats from "./CtcStats";
import StudentRegistered from "./StudentRegistered";
import StudentPlaced from "./StudentPlaced";
import { fetchBranchWiseRegAndPlaced } from "../../redux/features/getBranchwisePlaced";
import LocationPlacementStats from "./LocationPlacementStats";
import GenderPlacementStats from "./GenderPlacementStats";
import NewRecurring from "./NewRecurring";
import ReportLoading from "./ReportLoading";
import CompanyPercentagePlacement from "./CompanyPercentagePlacmentStats";
const ReportsList = () => {
  const { openMenu } = useSelector((state) => state.openMenu);

  const { loading } = useSelector((state) => state.placementGraph);
  const { user } = useSelector((state) => state.loginReducer);
  const { selectedYear } = useSelector((state) => state.reportsFilter);
  // const [year, setYear] = useState('2022');

  const { report } = useSelector((state) => state.getTopReports);
  const { totalStudentsCount } = useSelector(
    (state) => state.studentsPlacedCount
  );
  
  const { companyStatus } = useSelector((state) => state.getConnectionCount);


  const dispatch = useDispatch();

  const handleChange = (value) => {
    dispatch(updateSelectedYear(value || ''));
  };

  const currentYear = new Date().getFullYear();

  const years = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3,
  ];
  useEffect(() => {
    dispatch(fetchCompaniesCountByBranch({ year: selectedYear }))
    dispatch(fetchBranchWiseRegAndPlaced({ year: selectedYear }))
    dispatch(fetchStudentPlacedByGender({ year: selectedYear }))
    dispatch(fetchTotalPlacedStudentsCount({ year: selectedYear }))
    dispatch(fetchPlacedCompanyStudentsCount({ year: selectedYear }))
    dispatch(fetchTopReports({ year: selectedYear }))
    dispatch(fetchCtcOffered({ year: selectedYear }))
    dispatch(fetchCompanyWisePercentagePlaced({ year: selectedYear }))
    dispatch(fetchNewVsRecurringCount({ year: selectedYear }))
    dispatch(fetchPlacementGraph())
    dispatch(fetchCompanyDropdown('hire-type'))
    dispatch(getConnectionStatusCount({ year: selectedYear }))
  }, [selectedYear])

  return (
    <div className="mt-3">
      {loading ? (
        <ReportLoading />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Placement Statistics</h1>
            <Select
              onValueChange={(value) => handleChange(value)}
              defaultValue={selectedYear}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Academic Year" />
              </SelectTrigger>
              <SelectContent>
                {years.map((year, i) => {
                  return (
                    <SelectItem key={i} value={year}>
                      {year}
                    </SelectItem>
                  );
                })}
                 <SelectItem value={null}>None</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-6 gap-x-5 my-6">
            <ReportCard
              //  percentage={report?.studentsYetTobePlacedPercentage}
              title={"Students Registered"}
              count={report?.studentRegistered}
              total={Math.round(
                report?.studentRegistered /
                  ((report?.studentsYetTobePlacedPercentage +
                    Number(report?.studentPlacedPercentage)) /
                    100)
              )}
              actionType="STUDENTS_REGISTERED"
              yearFilter = {selectedYear}
            />
            <ReportCard
              percentage={report?.studentPlacedPercentage}
              title={"Students Placed"}
              count={totalStudentsCount?.placed}
              total={Math.round(
                report?.studentRegistered /
                  ((report?.studentsYetTobePlacedPercentage +
                    Number(report?.studentPlacedPercentage)) /
                    100)
              )}
              actionType="STUDENTS_PLACED"
              yearFilter = {selectedYear}
            />
            <ReportCard
              percentage={report?.studentsYetTobePlacedPercentage}
              title={"Students Remaining"}
              count={totalStudentsCount?.remaining}
              total={Math.round(
                report?.studentRegistered /
                  ((report?.studentsYetTobePlacedPercentage +
                    Number(report?.studentPlacedPercentage)) /
                    100)
              )}
              actionType="STUDENTS_REMAINING"
              yearFilter = {selectedYear}
            />
            <ReportCard
              title={"Companies Visited"}
              count={report?.companiesVisited}
              actionType="COMPANIES_VISITED"
            />
            <ReportCard
              title={"Invites Sent"}
              count={companyStatus?.Approached}
              total={companyStatus?.Approached}
              actionType="INVITES_SENT"
            />
            <ReportCard
              title={"Invites Accepted"}
              count={companyStatus?.Accepted}
              total={companyStatus?.Approached}
              actionType="INVITES_ACCEPTED"
            />
          </div>
          <div className="grid grid-cols-2 gap-x-5">
            <PlacementProgressGraph />
            <CompaniesVisitedByBranch />
          </div>
          <div className="grid grid-cols-2 gap-x-5 mt-6">
            <CompaniesPlacement />
            <CtcStats />
          </div>
          <div className="grid grid-cols-2 gap-x-5 mt-6">
            <StudentRegistered />
            <StudentPlaced />
          </div>
          <div className="grid grid-cols-4 gap-x-5 mt-6">
            <LocationPlacementStats />
            <GenderPlacementStats />
            <CompanyPercentagePlacement />
            <NewRecurring />
          </div>
        </>
      )}
    </div>
  );
};
export default ReportsList;
