import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchCompaniesCountByBranch = createAsyncThunk('fetchCompaniesCountByBranch', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/reports/companies-visited?year=${obj.year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const companyVisitedCount = createSlice({
    name: 'companiesVisited',
    initialState: {
        loading: false,
        companyVisitedCountByBranch: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompaniesCountByBranch.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchCompaniesCountByBranch.fulfilled, (state, action) => {
            state.loading = false;
            state.companyVisitedCountByBranch = action.payload
        })
        builder.addCase(fetchCompaniesCountByBranch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default companyVisitedCount.reducer