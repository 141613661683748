import { Button } from "../../ui/button"
import MemberDetails from "../MemberDetails"

export const memberColumns = [
    {
        id: "srno",
        header: "Sr No.",
        cell: ({ row }) => {
            const member = row.index
            return <p>{member + 1}</p>
        }
    },
    {
        accessorKey: "name",
        header: "Name",
    },
    {
        accessorKey: "adminRights",
        header: "Admin Rights",
        cell: ({ row }) => {
            const member = row.original
            return member.adminRights ? <p>YES</p> : <p>NO</p>
        }
    },
    {
        id: "details",
        header: "Access Details",
        cell: ({ row }) => {

            const member = row.original
            return <MemberDetails memberId={member.id} />
        }
    },
]