import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetails } from "../../redux/features/companyDetailSlice";
import { getAllConnections } from "../../redux/features/connectionGetSlice";
import CompanyDetailLoading from "./CompanyDetailLoading";
import CompanyDetailsHeader from "./CompanyDetailsHeader";
import CompanyDetailsDescription from "./CompanyDetailsDescription";
import CompanyJobRoles from "./CompanyJobRoles";
import { getCompanyJobRoles } from "../../redux/features/getCompanyJobRoles";

const CompanyDetails = () => {

  const { id } = useParams();
  const [follow, setFollow] = useState("Follow");
  const isMobile = useMediaQuery({
    query: "(max-width: 620px)",
  });

  //obtaining the company details from the store
  const { company, loading, error } = useSelector((state) => state.getCompanyDetail)
  const dispatch = useDispatch()
  const { success: addSuccess } = useSelector((state) => state.addConnection)
  const { success: deleteSuccess } = useSelector((state) => state.deleteInvitation)
  const { user } = useSelector((state) => state.loginReducer)



  useEffect(() => {
    //grabs the id from the url and passes as a parameter to the function to fetch the company details via id
    dispatch(fetchCompanyDetails({ id: id }))
    dispatch(getAllConnections())
    dispatch(getCompanyJobRoles(id))
  }, [addSuccess, deleteSuccess])

  return (
    <div className="flex flex-col mt-3 px-4">
      {
        loading ? <CompanyDetailLoading /> : <div>
          <CompanyDetailsHeader />
          <CompanyDetailsDescription />

          <CompanyJobRoles />
        </div>
      }
    </div>
  )
}
export default CompanyDetails