import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from '../../headersService'
export const addDrives = createAsyncThunk('addDrives', async (obj, { rejectWithValue }) => {
    try {
        const customConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/drive`, obj.body, customConfig)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const addDriveSlice = createSlice({
    name: 'postdrive',
    initialState: {
        loading: false,
        success: false,
        error: null
    },
    reducers: {
        resetAddDriveSuccess: (state) => {
            state.success = false
        },
        resetDriveError: (state) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addDrives.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(addDrives.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
        })
        builder.addCase(addDrives.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export const { resetAddDriveSuccess, resetDriveError } = addDriveSlice.actions
export default addDriveSlice.reducer