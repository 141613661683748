import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchTotalPlacedStudentsCount = createAsyncThunk('fetchTotalPlacedStudentsCount', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/reports/placement-stats?year=${obj.year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const studentsPlacedCountSlice = createSlice({
    name: 'studentPlacedCount',
    initialState: {
        loading: false,
        totalStudentsCount: {},
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTotalPlacedStudentsCount.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchTotalPlacedStudentsCount.fulfilled, (state, action) => {
            state.loading = false;
            state.totalStudentsCount = action.payload
        })
        builder.addCase(fetchTotalPlacedStudentsCount.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default studentsPlacedCountSlice.reducer