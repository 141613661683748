import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from '../../headersService'
export const endDrive = createAsyncThunk('endDrive', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/job/end-drive/${driveId}`)
        return data
    } catch (error) {
        console.log(rejectWithValue(error))
        return rejectWithValue(error)
    }
})

export const endDriveSlice = createSlice({
    name: 'endDrive',
    initialState: {
        loading: false,
        success: false,
        error: null,
        message : null
    },
    reducers: {
        resetEndDriveSuccess: (state) => {
            state.success = false
        },
        resetEndDriveError: (state) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(endDrive.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(endDrive.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.message = action.payload.message
        })
        builder.addCase(endDrive.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetEndDriveSuccess,resetEndDriveError } = endDriveSlice.actions
export default endDriveSlice.reducer