import { BiDotsVertical } from "react-icons/bi"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { useState } from "react"
import { updateStudentStatusApplication } from "../../redux/features/updateStudentApplicationStatusSlice"
import { useDispatch, useSelector } from "react-redux"
import { Dialog, DialogContent, DialogFooter, DialogDescription, DialogHeader, DialogTrigger, DialogTitle } from "../ui/dialog"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Label } from "../ui/label"

const optionsEnum = {
    ACCEPT: "Accepted",
    REJECT: "Rejected",
    MARK_AS_PLACED: "Mark as Placed",
}
const UpdateStudentRoundStatus = ({ student }) => {

    const [placedDialogOpen, setPlacedDialogOpen] = useState(false)
    const [ctcOffered, setCtcOffered] = useState(null)
    const dispatch = useDispatch()
    const closePlacedDialog = () => {
        setPlacedDialogOpen(false)
    }
    const handleUpdateStatus = (status) => {
        dispatch(updateStudentStatusApplication({ updatedStatus: status, roundId: student.roundId, applicationId: student.id, ctcOffered: ctcOffered, driveId: student.driveId, studentId: student.studentId }))
    }
    const { particularDriveDetail: driveDetail } = useSelector((state) => state.getParticularDriveDetails)


    return (
        <div onClick={(e) => e.stopPropagation()}>
            {
                driveDetail.controlled_by === 'COL' && student.currentStatus === "Applied" && <DropdownMenu>
                    <DropdownMenuTrigger>
                        <BiDotsVertical />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuItem onClick={() => handleUpdateStatus(optionsEnum.ACCEPT)}>Accept</DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleUpdateStatus(optionsEnum.REJECT)}>Reject</DropdownMenuItem>
                        <DropdownMenuItem onClick={() => setPlacedDialogOpen(true)}>Mark as Placed</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            }

            <Dialog open={placedDialogOpen} onOpenChange={setPlacedDialogOpen}>
                <DialogTrigger>

                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className="text-xl">Place Student</DialogTitle>
                        <DialogDescription className="mt-3 flex flex-col space-y-3">
                            <div className="flex flex-col gap-y-4">
                                <div className="flex items-center gap-x-4 w-full">
                                    <div className="flex flex-col gap-y-2 w-1/2">
                                        <Label className="text-black font-semibold" htmlFor="studentName">Student Name</Label>
                                        <Input disabled name="studentName" placeholder="Company Name" defaultValue={student.name} />
                                    </div>
                                    <div className="flex flex-col gap-y-2 w-1/2">
                                        <Label className="text-black font-semibold" htmlFor="email">Email</Label>
                                        <Input disabled name="email" placeholder="Company Address" defaultValue={student.email} />
                                    </div>
                                </div>
                                <div className="flex items-center gap-x-4 w-full">
                                    <div className="flex flex-col gap-y-2 w-1/2">
                                        <Label className="text-black font-semibold" htmlFor="jobrollNoProfile">Roll No</Label>
                                        <Input disabled name="rollNo" placeholder="Job Profile" defaultValue={student.rollNo} />
                                    </div>
                                    <div className="flex flex-col gap-y-2 w-1/2">
                                        <Label className="text-black font-semibold" htmlFor="ctcOffered">Ctc Offered</Label>
                                        <Input onChange={(e) => setCtcOffered(e.target.value)} name="ctcOffered" placeholder="Enter ctc offered" defaultValue={ctcOffered} />
                                    </div>
                                </div>
                            </div>
                        </DialogDescription>
                    </DialogHeader>

                    <DialogFooter className="flex items-center">
                        <Button onClick={() => setPlacedDialogOpen(false)} variant="outlined" className="py-1 rounded-2xl px-6" type="submit">Cancel</Button>
                        <Button onClick={() => handleUpdateStatus(optionsEnum.MARK_AS_PLACED)} variant="primary" className="py-1 rounded-2xl px-6" type="submit">Place</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default UpdateStudentRoundStatus