import { Skeleton } from "../ui/skeleton"

const CompanyLoading = () => {
  return (
    <div className="flex flex-col items-center space-y-3">
        {
            [1,2,3,4,5,6,7,8,9,10].map((item,i) => {
                return <Skeleton key={i} className="h-10 w-full" />
            })
        }
    </div>
  )
}
export default CompanyLoading