import { useSelector } from "react-redux"
import JobRolesTable from "./JobRolesTable/JobRolesTable"
import { jobRolesColumns } from "./JobRolesTable/JobRolesColumns"
import { DataTable } from "../reusable/table/DataTable"

const CompanyJobRoles = () => {

  const { jobRoles } = useSelector((state) => state.jobRolesReducer)

  return (
    <div className="flex flex-col">
      <h2 className="text-primaryButtonColor text-xl font-bold">Job Roles</h2>
      {
        jobRoles && <DataTable hasClick={false} hasPagination={false} columns={jobRolesColumns} data={jobRoles} />
      }
    </div>
  )
}
export default CompanyJobRoles