import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../headersService";


export const updateConnectionReq = createAsyncThunk('updateConnectionReq', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.patch(`${process.env.REACT_APP_BACKEND_URL}/api/connection/${obj.connectionId}?status=${obj.connectionStatus}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const connectionUpdateSlice = createSlice({
    name: 'connection',
    initialState: {
        loading: false,
        success: false,
        error: null
    },
    reducers: {
        resetConnectionUpdateSuccess: (state) => {
            state.success = false
        },
        resetConnectionUpdateError: (state) => {
            state.error = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateConnectionReq.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateConnectionReq.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
        })
        builder.addCase(updateConnectionReq.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetConnectionUpdateSuccess,resetConnectionUpdateError } = connectionUpdateSlice.actions
export default connectionUpdateSlice.reducer