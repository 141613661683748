import { useDispatch } from "react-redux"
import { Button } from "../ui/button"
import CommonAlertDialog from "../reusable/modals/CommonAlertDialog"
import { deleteConnectionReq } from "../../redux/features/removeInvitaionSlice"


const DeleteInviteModal = ({ connectionId }) => {

    const dispatch = useDispatch()

    const triggerButton = <Button variant="outlined" className="bg-archiveColor text-black shadow-md" size="md">Delete Invite</Button>
    const actionButton = <Button onClick={() => dispatch(deleteConnectionReq(connectionId))} variant="primary">Continue</Button>

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <CommonAlertDialog title={`Are you sure you want to delete invitation`} triggerButton={triggerButton} actionButton={actionButton} description={"This action can't be undone are you sure you want to delete your connection"} />
        </div>
    )
}
export default DeleteInviteModal