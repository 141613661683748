import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'

export const fetchCompanies = createAsyncThunk('fetchCompanies', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/company?location=${obj.location || ''}&branch=${obj.branch || ''}&name=${obj.name || ''}&status=${obj.status || ''}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        loading: false,
        companies: {},
        searchCompanyName: "",
        error: null
    },
    reducers : {
        updateSearchCompanyName: (state,action) => {
            state.searchCompanyName = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanies.pending, (state, action) => {
            state.loading = true;
            state.companies = {}
        })
        builder.addCase(fetchCompanies.fulfilled, (state, action) => {
            state.loading = false;
            state.companies = action.payload
        })
        builder.addCase(fetchCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export const {updateSearchCompanyName} = companySlice.actions
export default companySlice.reducer