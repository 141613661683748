
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import ScheduleDrive from "./ScheduleDrive";
const DriveHeader = ({ status, setStatus }) => {
    const { type } = useParams()

    const handleChange = (value) => {
        setStatus(value);
    };
    const { driveDropdown } = useSelector((state) => state.getDriveDropdown)

    return (
        <div className="flex items-center gap-x-4 justify-between">
            <div className="flex items-center gap-x-4">
                <h1 className="font-bold text-2xl">Drive Database</h1>

                {
                    type === "on-campus-drive" &&
                    <ScheduleDrive>
                        <Button variant="primary" className="rounded-3xl shadow-md">
                            + Schedule Drive
                        </Button>
                    </ScheduleDrive>
                }
            </div>
            <div>

                <Select onValueChange={(value) => handleChange(value)} value={status}>
                    <div className="flex items-center gap-x-4">
                    <h1 className="font-semibold text-xl">Drive Status</h1>
                    <SelectTrigger className="w-[180px]">
                   
                        <SelectValue placeholder="Status" />
                    </SelectTrigger>

                    </div>
                    <SelectContent>
                        {
                            driveDropdown?.map((dropdown, i) => {
                                if (type === 'on-campus-drive') {
                                    return <SelectItem key={dropdown.id} value={dropdown.label}>{dropdown.label}</SelectItem>
                                } else {
                                    if (dropdown.label !== 'Request Sent') {
                                        return <SelectItem key={dropdown.id} value={dropdown.label}>{dropdown.label}</SelectItem>

                                    }
                                }
                            })
                        }
                    </SelectContent>
                </Select>
            </div>
        </div>
    )
}
export default DriveHeader