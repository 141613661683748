import { useSelector } from "react-redux"
import { Button } from "../ui/button"
import { useNavigate } from "react-router-dom";
import CustomImage from "../CustomImage";

const PlacedStudents = () => {

    const { students } = useSelector((state) => state.getAllStudents)
    const navigate = useNavigate();
    return (
        <div className="rounded-2xl border px-4 pt-6 pb-12 h-[611px]  shadow-lg">
            <div className="flex items-center justify-between">
                <h1 className="font-bold text-primaryTextColor text-xl">Recently Placed Students</h1>
                <Button onClick={() => navigate("/student")} variant="primary" className="py-0" size="sm">
                    See All
                </Button>
            </div>
            <div className="flex flex-col space-y-2 mt-3 overflow-auto h-full">
                {
                    students?.result?.map((student, i) => {
                        return <div key={i} className="flex items-center gap-x-4  cursor-pointer" onClick={() => navigate(`/student/${student.id}`)}>
                            <CustomImage className="w-12 h-12 object-contain rounded-full" src={student.profilePic} alt="Placed Student" section="student"  />
                            <div className="flex flex-col" >
                                <h3 className="font-semibold">{student.name}</h3>
                                <p className="font-light text-sm">{student.branch}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
export default PlacedStudents