import { createSlice } from "@reduxjs/toolkit"


export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        snackbarOpen: false,
        snackbarMessage : '',
        snackbarType: 'info'
    },
    reducers :{
        handleSnackbar : (state,action) =>{
            state.snackbarOpen = action.payload.open
            state.snackbarMessage = action.payload.message
            state.snackbarType = action.payload.type
        },
    }
})

export const {handleSnackbar} = snackbarSlice.actions
export default snackbarSlice.reducer