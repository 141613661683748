import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../../headersService'


export const downloadStudentSheet = createAsyncThunk('downloadStudentSheet', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/student/download-excel`, body, {
            responseType: "blob"
        })

        const href = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `student_sheet_xlsx`);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const downloadStudentSheetSlice = createSlice({
    name: 'Download Student Sheet',
    initialState: {
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(downloadStudentSheet.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(downloadStudentSheet.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(downloadStudentSheet.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export default downloadStudentSheetSlice.reducer