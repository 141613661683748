import "../styles/Sidebar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { closeMenu, open } from "../redux/features/menuSlice";
import { sidebarItems } from "../lib/sidebarItems";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./ui/hover-card"
import { useState } from "react";
import { cn } from "../lib/utils";

const Sidebar = ({ home }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.loginReducer)

  const { openMenu } = useSelector((state) => state.openMenu)
  const { pathname } = useLocation();

  const handleCloseMenu = () => {
    dispatch(closeMenu())
  };

  const handleExpandMenu = () => {
    dispatch(open())
  }


const SidebarItem = ({ item }) => {
    const isDrivePath = pathname.includes("drive");
    const isActive = pathname === item.link || pathname.includes(item.link);

    if (item.link === "/drive/on-campus") {
        return (
            <div className="z-[100]">
                <HoverCard>
                    <HoverCardTrigger asChild>
                        <div
                            className={cn(
                                `flex hover:bg-primaryButtonColor transition-all cursor-pointer gap-x-6 ${openMenu ? "items-center rounded-2xl shadow-md py-3 px-4" : "justify-center"}`,
                                openMenu && isDrivePath && "bg-primaryButtonColor",
                                openMenu && !isDrivePath && "bg-sidebarUnselectedBgColor"
                            )}
                        >
                            {openMenu ? (
                                <img
                                    className="max-w-[25px]"
                                    src={isDrivePath ? item.sidebarOpenSelectedIcon : item.sidebarOpenUnselectedIcon}
                                    alt=""
                                />
                            ) : (
                                <img
                                    className="max-w-[25px]"
                                    src={isDrivePath ? item.activeIcon : item.unSelectedIcon}
                                    alt=""
                                />
                            )}
                            {openMenu && <p className="text-white font-medium text-lg">{item.label}</p>}
                        </div>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-full p-1">
                        <div
                            className="bg-white hover:bg-dropdownBgColor py-2 px-6 cursor-pointer rounded-lg"
                            onClick={() => navigate('/drive/on-campus-drive')}
                        >
                            On Campus Drive
                        </div>
                        <div
                            className="bg-white hover:bg-dropdownBgColor py-2 px-6 cursor-pointer rounded-lg"
                            onClick={() => navigate('/drive/off-campus-drive')}
                        >
                            Off Campus Drive
                        </div>
                        <div
                            className="bg-white hover:bg-dropdownBgColor py-2 px-6 cursor-pointer rounded-lg"
                            onClick={() => navigate('/jobs')}
                        >
                            Job Forms
                        </div>
                    </HoverCardContent>
                </HoverCard>
            </div>
        );
    } else if (item.link === "/companies") {
        return (
            <div className="z-[100]" onClick={() => navigate(item.link)}>
                <HoverCard>
                    <HoverCardTrigger asChild>
                        <div
                            className={cn(
                                `flex hover:bg-primaryButtonColor transition-all cursor-pointer gap-x-6 ${openMenu ? "items-center rounded-2xl shadow-md py-3 px-4" : "justify-center"}`,
                                openMenu && pathname.includes("companies") && "bg-primaryButtonColor",
                                openMenu && !pathname.includes("companies") && "bg-sidebarUnselectedBgColor"
                            )}
                        >
                            {openMenu ? (
                                <img
                                    className="max-w-[25px]"
                                    src={pathname.includes("companies") ? item.sidebarOpenSelectedIcon : item.sidebarOpenUnselectedIcon}
                                    alt=""
                                />
                            ) : (
                                <img
                                    className="max-w-[25px]"
                                    src={pathname.includes("companies") ? item.activeIcon : item.unSelectedIcon}
                                    alt=""
                                />
                            )}
                            {openMenu && <p className="text-white font-medium text-lg">{item.label}</p>}
                        </div>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-full p-1">
                        <div
                            className="bg-white hover:bg-dropdownBgColor py-2 px-6 cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate('/corporatenetwork');
                            }}
                        >
                            Corporate Network
                        </div>
                    </HoverCardContent>
                </HoverCard>
            </div>
        );
    } else if (item.link === "/") {
        return (
            <div
                onClick={() => navigate(item.link)}
                className={cn(
                    `flex hover:bg-primaryButtonColor transition-all cursor-pointer gap-x-6 ${openMenu ? "items-center rounded-2xl shadow-md py-3 px-4" : "justify-center"}`,
                    openMenu && pathname === "/" && "bg-primaryButtonColor",
                    openMenu && pathname !== "/" && "bg-sidebarUnselectedBgColor"
                )}
            >
                {openMenu ? (
                    <img
                        className="max-w-[25px]"
                        src={pathname === "/" ? item.sidebarOpenSelectedIcon : item.sidebarOpenUnselectedIcon}
                        alt=""
                    />
                ) : (
                    <img
                        className="max-w-[25px]"
                        src={pathname === "/" ? item.activeIcon : item.unSelectedIcon}
                        alt=""
                    />
                )}
                {openMenu && <p className="text-white font-medium text-lg">{item.label}</p>}
            </div>
        );
    } else {
        return (
            <div
                onClick={() => navigate(item.link)}
                className={cn(
                    `flex hover:bg-primaryButtonColor transition-all cursor-pointer gap-x-6 ${openMenu ? "items-center rounded-2xl shadow-md py-3 px-4" : "justify-center"}`,
                    openMenu && isActive && "bg-primaryButtonColor",
                    openMenu && !isActive && "bg-sidebarUnselectedBgColor"
                )}
            >
                {openMenu ? (
                    <img
                        className="max-w-[25px]"
                        src={isActive ? item.sidebarOpenSelectedIcon : item.sidebarOpenUnselectedIcon}
                        alt=""
                    />
                ) : (
                    <img
                        className="max-w-[25px]"
                        src={isActive ? item.activeIcon : item.unSelectedIcon}
                        alt=""
                    />
                )}
                {openMenu && <p className="text-white font-medium text-lg">{item.label}</p>}
            </div>
        );
    }
};


  return (
    <div className={`fixed h-[88vh] transition-all duration-700 bg-white shadow-lg border rounded-lg py-8 px-6 mt-8 overflow-hidden`}
      onMouseOver={handleExpandMenu}
      onMouseLeave={handleCloseMenu}>
      <div className={`flex flex-col ${openMenu ? "space-y-4" : "space-y-12"}`}>
        {sidebarItems.map((item, index) => (
          <SidebarItem item={item} navigate={navigate} key={index} />
        ))}
      </div>
    </div>
  );
};
export default Sidebar;