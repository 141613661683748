import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../headersService";


export const deleteConnectionReq = createAsyncThunk('deleteConnectionReq', async (delId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.delete(`${process.env.REACT_APP_BACKEND_URL}/api/connection/${delId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const connectionRemoveSlice = createSlice({
    name: 'connection',
    initialState: {
        loading: false,
        success: false,
        error: null
    },
    reducers: {
        resetConnectionDeleteSuccess: (state) => {
            state.success = false
        },
        resetConnectionDeleteError: (state) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteConnectionReq.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(deleteConnectionReq.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
        })
        builder.addCase(deleteConnectionReq.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export const { resetConnectionDeleteSuccess,resetConnectionDeleteError } = connectionRemoveSlice.actions
export default connectionRemoveSlice.reducer