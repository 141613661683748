import { useDispatch, useSelector } from "react-redux";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { updateNotificationsRead } from "../../redux/features/updateNotificationReadSlice";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

const notificationTypeEnum = {
  DRIVE: "drive",
  COMPANY: "company",
  COLLEGE: "college",
  JOB: "job",
  USER: "user",
};

const Notification = () => {
  const { notifications } = useSelector((state) => state.notificationReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNotificationNavigation = (type, id, notificationId) => {
    if (type === notificationTypeEnum.DRIVE) {
      navigate(`/drive/drive-details/${id}`);
    }
    if (type === notificationTypeEnum.COMPANY) {
      navigate(`/companies/${id}`);
    }
    dispatch(updateNotificationsRead(notificationId));
  };

  const latestNotifications = notifications.slice(0, 3);

  return (
    <div className="border shadow-lg rounded-2xl px-4 py-6 col-span-1 row-span-2 h-fit">
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-primaryTextColor text-xl">
          Notifications
        </h1>

        <Dialog>
          <DialogTrigger>
            <Button variant="primary" className="py-0" size="sm">
              See All
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>All Notifications</DialogTitle>
              <DialogDescription>
                <div className="flex flex-col space-y-3 mt-3 max-h-96 overflow-y-auto">
                  {notifications.length === 0 ? (
                    <h2>No New Notifications</h2>
                  ) : (
                    notifications.map((item, i) => (
                      <div
                        key={item.id}
                        onClick={() =>
                          handleNotificationNavigation(
                            item.linked_entity_type,
                            item.linked_entity_id,
                            item.id
                          )
                        }
                        className="rounded-xl cursor-pointer hover:bg-white/80 border shadow-md px-4 py-1 w-full"
                      >
                        {!item.is_read && (
                          <div className="w-1 h-1 rounded-full bg-primaryTextColor"></div>
                        )}
                        <h3 className="font-semibold text-sm">{item.title}</h3>
                        <p className="text-xs font-light">{item.subtitle}</p>
                      </div>
                    ))
                  )}
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>

      <div className="mt-4 space-y-3">
        {latestNotifications.length === 0 ? (
          <h2>No New Notifications</h2>
        ) : (
          latestNotifications.map((item, i) => (
            <div
              key={item.id}
              onClick={() =>
                handleNotificationNavigation(
                  item.linked_entity_type,
                  item.linked_entity_id,
                  item.id
                )
              }
              className="rounded-xl cursor-pointer hover:bg-white/80 border shadow-md px-4 py-1 w-full"
            >
              {!item.is_read && (
                <div className="w-1 h-1 rounded-full bg-primaryTextColor"></div>
              )}
              <h3 className="font-semibold text-sm">{item.title}</h3>
              <p className="text-xs font-light">{item.subtitle}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Notification;
