import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getAllDriveDetails = createAsyncThunk('getAllDriveDetails', async (obj,{rejectWithValue}) => {
    try {        
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/drive?campusType=${obj.campusType}&driveStatus=${obj.status || ''}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const driveDetailsSlice = createSlice({
    name: 'allDrive',
    initialState: {
        loading: false,
        allDriveDetails: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getAllDriveDetails.pending,(state,action)=>{
            state.loading = true;
        })
        builder.addCase(getAllDriveDetails.fulfilled,(state,action)=>{
            state.loading = false;
            state.allDriveDetails = action.payload
        })
        builder.addCase(getAllDriveDetails.rejected,(state,action)=>{
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default driveDetailsSlice.reducer