import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const CompanyDetailsDescription = () => {

    const { company } = useSelector((state) => state.getCompanyDetail)

    return (
        <div className="grid grid-cols-3 space-x-6 my-6">
            <div className="shadow-lg flex flex-col gap-y-3 rounded-lg border py-4 px-6">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h1 className="text-primaryTextColor font-medium">Industry</h1>
                        <div className="flex">
                            {company?.industry?.map((industryName, i) => (
                                <React.Fragment key={i}>
                                    <p>{industryName}</p>
                                    {/* Conditionally render a comma if it's not the last item */}
                                    {i < company.industry.length - 1 && <span>, </span>}
                                </React.Fragment>))}
                        </div>

                    </div>
                    <div className="flex flex-col">
                        <h1 className="text-primaryTextColor font-medium">No of Employees</h1>
                        <p>{company?.employeeCount}</p>
                    </div>
                </div>
                <div className="flex flex-col">
                    <h1 className="text-primaryTextColor font-medium">Website</h1>
                    <Link to={`${company?.website}`} target="_blank" className="text-linkTextColor underline">
                        {company?.website}
                    </Link>
                </div>
            </div>
            <div className="col-span-2 shadow-lg rounded-lg border py-4 px-6">
                <h1 className="text-primaryTextColor font-medium">About the Company</h1>
                <p>{company?.description}</p>
            </div>
        </div>
    )
}
export default CompanyDetailsDescription