import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const addConnectionReq = createAsyncThunk('addConnectionReq', async (obj, { rejectWithValue }) => {
    try {
        const customConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/connection`, obj.body , customConfig)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const connectionAddSlice = createSlice({
    name: 'connection',
    initialState: {
        loading: false,
        success: false,
        error: null
    },
    reducers: {
        resetConnectionAddSuccess: (state) => {
            state.success = false
        },
        resetConnectionAddError: (state) => {
            state.error = null
        },

    },
    extraReducers: (builder) => {
        builder.addCase(addConnectionReq.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(addConnectionReq.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
        })
        builder.addCase(addConnectionReq.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetConnectionAddSuccess,resetConnectionAddError } = connectionAddSlice.actions
export default connectionAddSlice.reducer