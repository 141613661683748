import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchAllEvents = createAsyncThunk('fetchAllEvents', async (obj, { rejectWithValue }) => {
    try {
        const {data} = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/home/events`,{
            params: {
                eventsBeforeDate:obj.endDate,
                eventsAfterDate:obj.startDate
            }
        })
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        loading: true,
        events: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllEvents.pending, (state, action) => {
            state.loading = true;
            state.events = {}
        })
        builder.addCase(fetchAllEvents.fulfilled, (state, action) => {
            state.loading = false;
            state.events = action.payload
        })
        builder.addCase(fetchAllEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default eventsSlice.reducer