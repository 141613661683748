import { Pie } from "react-chartjs-2";

const LocationPlacementStats = () => {

  const chartData = {
    labels: ["North", "East", "West", "South"],
    datasets: [
      {
        label: "Companies",
        data: [8, 5, 3, 4],
        backgroundColor: ["#5E3FBE", "#F4F0FD", "#E5DAFB", "#CBB6FD"]
      },
    ],
  };
  return (
    <div>
      <div className="rounded-lg shadow-lg px-4 py-2">
        <h2 className="text-primaryTextColor text-lg font-semibold">Location Wise Placement Statistics</h2>
        <Pie data={chartData}></Pie>
      </div>
    </div>
  )
}
export default LocationPlacementStats