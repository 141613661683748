import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchStudentRegisteredAndPlaced = createAsyncThunk('fetchStudentRegisteredAndPlaced', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/reports/placed-reg-students-yearwise-graph`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const studentPlacedSlice = createSlice({
    name: 'year',
    initialState: {
        loading: false,
        studentRegistered: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStudentRegisteredAndPlaced.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchStudentRegisteredAndPlaced.fulfilled, (state, action) => {
            state.loading = false;
            state.studentRegistered = action.payload
        })
        builder.addCase(fetchStudentRegisteredAndPlaced.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default studentPlacedSlice.reducer