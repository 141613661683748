import { Skeleton } from "../ui/skeleton"

const AnnouncementDetailLoading = () => {
  return (
    <div className="flex flex-col gap-y-4">
        <Skeleton className="w-28 h-6 rounded-md" />
        <Skeleton className="w-full h-20 rounded-md" />
    </div>
  )
}
export default AnnouncementDetailLoading