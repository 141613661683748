import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getConnectionStatusCount = createAsyncThunk('getConnectionStatusCount', async (obj,{rejectWithValue}) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/reports/company-stats?year=${obj.year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const connectionCount = createSlice({
    name: 'connection',
    initialState: {
        loading: false,
        companyStatus : null,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getConnectionStatusCount.pending,(state,action)=>{
            state.loading = true;
        })
        builder.addCase(getConnectionStatusCount.fulfilled,(state,action)=>{
            state.loading = false;
            state.companyStatus = action.payload
        })
        builder.addCase(getConnectionStatusCount.rejected,(state,action)=>{
            state.loading = false;
            state.error = action.payload
        })
    }
})
export default connectionCount.reducer