import { createSlice } from "@reduxjs/toolkit"


export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        openMenu: false,
    },
    reducers :{
        open : (state) =>{
            state.openMenu = true
        },
        closeMenu : (state) =>{
            state.openMenu = false
        }
    }
})

export const {open,closeMenu} = menuSlice.actions
export default menuSlice.reducer