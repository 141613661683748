import { useNavigate } from "react-router-dom"
import DriveList from "../components/drives/DriveList"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../components/ui/button"
import { handleSnackbar } from "../redux/features/snackbarSlice"


const DrivePage = () => {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.loginReducer)
    if (!user?.permission_manage_drives) {
        dispatch(handleSnackbar({ type: 'error', message: "You do not have permission to access this page", open: true }))
        return (<div className="flex items-center h-screen justify-center">
            <Button onClick={() => navigate(-1)} variant='contained'>Go Back</Button>
        </div>)
    }
    return (
        <div className="">
            <DriveList />
        </div>
    )
}
export default DrivePage