import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";

const NewRecurring = () => {

  const { newRecurringCount } = useSelector((state) => state.newRecurring)

  const chartData = {
    labels: ["New", "Recurring"],
    datasets: [
      {
        label: "Companies",
        data: [newRecurringCount?.new, newRecurringCount?.recurring],
        backgroundColor: ["#C9EFD0", "#67C587"]
      },
    ],
  };
  return (
    <div>
      <div className="rounded-lg shadow-lg px-4 py-2">
        <h2 className="text-primaryTextColor text-lg font-semibold">New V/S Recurring Companies</h2>
        <Pie data={chartData}></Pie>
      </div>
    </div>
  )
}
export default NewRecurring