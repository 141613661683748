import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../headersService";


export const getRegisteredDriveStudents = createAsyncThunk('getRegisteredDriveStudents', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/job/college-drive/view-registration/?driveId=${driveId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const registeredDriveStudentsSlice = createSlice({
    name: 'Drive Detail',
    initialState: {
        loading: false,
        registeredStudents: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getRegisteredDriveStudents.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getRegisteredDriveStudents.fulfilled, (state, action) => {
            state.loading = false;
            state.registeredStudents = action.payload
        })
        builder.addCase(getRegisteredDriveStudents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default registeredDriveStudentsSlice.reducer