import { Skeleton } from "../ui/skeleton"

const ReportLoading = () => {
    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center justify-between gap-x-6">
                <Skeleton className="w-1/4 h-10" />
                <Skeleton className="w-1/4 h-10" />
            </div>
            <div className="grid grid-cols-6 gap-x-5 my-6">
                {
                    [1, 2, 3, 4, 5, 6].map((_, i) => {
                        return <Skeleton className="h-40" />
                    })
                }
            </div>
            <div className="flex items-center space-x-5">
                <Skeleton className="w-1/2 h-[40vh]" />
                <Skeleton className="w-1/2 h-[40vh]" />
            </div>
        </div>
    )
}
export default ReportLoading