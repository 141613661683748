import { Button } from "../ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

const DriveRoles = ({ jobRoles, controlledBy, jobTitles = [] }) => {
  return (
    <div className="mt-4">
      <h1 className="font-semibold text-2xl">Job Roles</h1>
      <div className="mt-2">
        {controlledBy === "COM" ? (
          <Carousel
            opts={{
              align: "start",
            }}
            className="w-full"
          >
            <CarouselContent className="-ml-2 md:-ml-4">
              {jobRoles?.map((job, index) => (
                <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                  <div className="border rounded-lg p-4 shadow-xl">
                    <div className="flex flex-col space-y-2">
                      <p className="text-primaryTextColor text-lg font-semibold">
                        {job.title}
                      </p>

                      <div className="flex flex-col space-y-2">
                        <p>Job Description</p>
                        <div className="border rounded-xl shadow-md p-2 line-clamp-6 overflow-y-auto">
                          {jobRoles.description.substring(0, 180)}<span className="text-red-500">...</span>
                          <Dialog>
                            <DialogTrigger asChild>
                              <button className="text-red-500 underline ml-1">
                                expand
                              </button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[425px]">
                              <DialogHeader>
                                <DialogTitle>Job Description</DialogTitle>
                              </DialogHeader>
                              <DialogDescription className="text-justify">
                                {jobRoles.description}
                              </DialogDescription>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <p>Eligibility Criteria</p>
                        <div className="border rounded-xl shadow-md p-2 line-clamp-6 overflow-y-auto">
                          {job.eligibility_criteria}
                        </div>
                      </div>
                    </div>
                    {/* <div className="w-full flex justify-end mt-4">
                      <Button variant="primary" className="w-fit" size="sm">
                        Know More
                      </Button>
                    </div> */}
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        ) : (
          <div className="border rounded-lg p-4 shadow-xl">
            {jobTitles && (
              <p className="text-primaryTextColor text-lg">
                {jobTitles.join(", ")}
              </p>
            )}

            <div className="grid grid-cols-2 space-x-6">
              <div className="flex flex-col space-y-2">
                <p>Job Description</p>
                <div className="border rounded-xl shadow-md p-2 h-full">
                {(jobRoles.description || '').substring(0, 180)}<span className="text-red-500">...</span>
                <Dialog>
                            <DialogTrigger asChild>
                              <button className="text-red-500 underline ml-1 bg-none">
                                expand
                              </button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[425px]">
                              <DialogHeader>
                                <DialogTitle>Job Description</DialogTitle>
                              </DialogHeader>
                              <DialogDescription className="text-justify">
                                {jobRoles.description}
                              </DialogDescription>
                            </DialogContent>
                          </Dialog>
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <p>Eligibility Criteria</p>
                <div className="border rounded-xl shadow-md p-2 h-full">
                  {(jobRoles.eligibility_criteria || '').substring(0, 180)}<span className="text-red-500">...</span>
                  <Dialog>
                            <DialogTrigger asChild>
                              <button className="text-red-500 underline ml-1">
                                expand
                              </button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[425px]">
                              <DialogHeader>
                                <DialogTitle>Eligibility Criteria</DialogTitle>
                              </DialogHeader>
                              <DialogDescription className="text-justify">
                              {jobRoles.eligibility_criteria}
                              </DialogDescription>
                            </DialogContent>
                          </Dialog>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default DriveRoles;
