import downloadIcon from '../../assets/icons/download.svg';
import viewIcon from '../../assets/icons/view.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import mailIcon from '../../assets/icons/mail.svg';

export const Icons = {
    DOWNLOAD: downloadIcon,
    VIEW: viewIcon,
    DELETE: deleteIcon,
    MAIL: mailIcon,
}

export const Icon = ({ icon, className = "", ...props }) => {
    return <div style={{
        // backgroundColor: props.style?.color?props.style.color:"black",
        height: "25px",
        width: "25px",
        marginRight: "2px",
        ...props.style,
        backgroundImage: `url(${icon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
    }} className={`align-middle items-center ${className}`}>

    </div>
};