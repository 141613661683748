import axiosInstance from "../../../headersService";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


export const createDriveAnnouncements = createAsyncThunk('createDriveAnnouncements', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/create-drive-announcement`, body)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const createAnnouncementSlice = createSlice({
    name: 'Announcements',
    initialState: {
        loading: false,
        successMsg: null,
        error: null
    },
    reducers: {
        resetAnnouncementSuccessMsg: (state, action) => {
            state.successMsg = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createDriveAnnouncements.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(createDriveAnnouncements.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = action.payload.message
        })
        builder.addCase(createDriveAnnouncements.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export const { resetAnnouncementSuccessMsg } = createAnnouncementSlice.actions
export default createAnnouncementSlice.reducer