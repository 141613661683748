import { createSlice } from "@reduxjs/toolkit"


export const reportsFilterSlice = createSlice({
    name: 'Reports Filter',
    initialState: {
        selectedYear : ''
    },
    reducers :{
        updateSelectedYear : (state,action) =>{
            state.selectedYear = action.payload
        },
    }
})

export const {updateSelectedYear} = reportsFilterSlice.actions
export default reportsFilterSlice.reducer