import { useSelector } from "react-redux";
import PlacementStatsTable from "./studentTable/PlacementStatsTable";
import KeyValuedetails from "./KeyValuedetails";
import { PlacementStatsColumns } from "./studentTable/PlacementStatsColumn";
import { DataTable } from "../reusable/table/DataTable";

const StudentInfo = () => {
    const { studentDetail } = useSelector((state) => state.getStudentDetail);

    const { loading, placedDetails } = useSelector((state) => state.studentPlacedDetails);

    const formatMark = (mark, type) => {
        if (type === "PERCENTAGE") {
            return `${mark}%`;
        } else if (type === "CGPA") {
            return `${mark} GPA`;
        } else {
            return mark;
        }
    };

    return (
        <div className="grid grid-cols-3 gap-x-10">
            <div className="rounded-lg border-2 shadow-lg px-6 py-4">
                <h2 className="text-primaryButtonColor font-bold text-lg">Education Details</h2>
                <p className="mt-2">Academic Performance</p>

                <div className="flex items-center justify-evenly mt-1 w-full">
                {studentDetail?.Percentage10th && (
                    <div className="flex flex-col rounded-full border-8 justify-center py-2 border-primaryTextColor aspect-square w-full text-center mx-0.5 max-h-[90px] max-w-[90px]">
                        <div className="text-xs">10th</div>
                        <div className="text-sm font-medium">{formatMark(studentDetail?.Percentage10th,studentDetail?.marks_type_10th)}</div>
                        <div className="text-xs">{studentDetail?.GraduationYear10th ? studentDetail.GraduationYear10th : "-"}</div>
                    </div>
                )}
                    {studentDetail?.Percentage12th && (
                        <div className="flex flex-col rounded-full border-8 justify-center py-2 border-primaryTextColor aspect-square w-full text-center mx-0.5 max-h-[90px] max-w-[90px]">
                            <div className="text-xs">12th</div>
                            <div className="text-sm font-medium">{formatMark(studentDetail?.Percentage12th, studentDetail?.marks_type_12th)}</div>
                            <div className="text-xs">{studentDetail?.GraduationYear12th ? studentDetail.GraduationYear12th : "-"}</div>
                        </div>
                    )}
                    {studentDetail?.diplomaPercentage && (
                        <div className="flex flex-col rounded-full border-8 justify-center py-2 border-primaryTextColor aspect-square w-full text-center mx-0.5 max-h-[90px] max-w-[90px]">
                            <div className="text-xs">Diploma</div>
                            <div className="text-sm font-medium">{formatMark(studentDetail?.diplomaPercentage, studentDetail?.marks_type_diploma)}</div>
                            <div className="text-xs">{studentDetail?.diplomaGraduationYear ? studentDetail.diplomaGraduationYear : "-"}</div>
                        </div>
                    )}
                      {studentDetail?.gpa && (
                    <div className="flex flex-col rounded-full border-8 justify-center py-2 border-primaryTextColor aspect-square w-full text-center mx-0.5 max-h-[90px] max-w-[90px]">
                        <div className="text-xs">CGPA</div>
                        <div className="text-xl">{formatMark(studentDetail?.gpa, studentDetail?.marks_type_graduation)}</div>
                        <div className="text-xs">{studentDetail?.graduationYear}</div>
                    </div>
                      )}
                </div>
                <div className="flex flex-col gap-y-1 py-2">
                    <KeyValuedetails title={"KT Live"} value={studentDetail?.KTLive} />
                    <KeyValuedetails title={"KT Resolved"} value={studentDetail?.KTResolved} />
                    <KeyValuedetails title={"Email"} value={studentDetail?.email} />
                    <KeyValuedetails title={"Alternate Email"} value={studentDetail?.alternateEmail} />
                    <KeyValuedetails title={"Linkedin"} value={studentDetail?.linkedin_url} />
                    <KeyValuedetails title={"Roll Number"} value={studentDetail?.rollNo} />
                    <KeyValuedetails title={"Date Of Birth"} value={studentDetail?.dateOfBirth} />
                    <KeyValuedetails title={"Gender"} value={studentDetail?.gender} />
                    <KeyValuedetails title={"Category"} value={studentDetail?.category} />
                </div>
            </div>
            <div className="grid col-span-2 border-2 rounded-lg shadow-lg px-6 py-4">
                {placedDetails && <DataTable hasPagination={false} hasClick={false} columns={PlacementStatsColumns} data={placedDetails} />}
            </div>
        </div>
    );
};

export default StudentInfo;
