import { Trash } from "lucide-react"
import { Button } from "../ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { deleteConnectionReq, resetConnectionDeleteError, resetConnectionDeleteSuccess } from "../../redux/features/removeInvitaionSlice"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "sonner"
import { useEffect } from "react"

const CompanyWithdraw = ({ connectionId }) => {

    const dispatch = useDispatch()
    const { success: deleteSuccess,error } = useSelector((state) => state.deleteInvitation)

    const handleWithdraw = () => {
        dispatch(deleteConnectionReq(connectionId))
    }


    return (
        <div onClick={(e) => e.stopPropagation()}>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <Button variant="primary" className="shadow-md w-[160px] rounded-3xl text-base bg-[#994D23] hover:bg-[#994D23]/70">
                        Invite Sent
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem className='flex items-center gap-x-2 cursor-pointer' onClick={handleWithdraw} >
                        <Trash size={16} />
                        Withdraw Invitation
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}
export default CompanyWithdraw