import { useDispatch, useSelector } from "react-redux"
import { handleSnackbar } from "../redux/features/snackbarSlice";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';


export const Message = () => {

    const { snackbarOpen, snackbarType, snackbarMessage } = useSelector((state) => state.snackbarReducer)
    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(handleSnackbar({open:false,message:snackbarMessage,type:snackbarType}))
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Change the position here
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity={snackbarType}
            >
                {snackbarMessage}
            </MuiAlert>
        </Snackbar>
    )
}