import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchCtcOffered = createAsyncThunk('fetchCtcOffered', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/reports/ctc-offered?year=${obj.year}`);
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const ctcOfferedSlice = createSlice({
    name: 'ctcOffered',
    initialState: {
        loading: false,
        ctcOfferedData: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCtcOffered.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchCtcOffered.fulfilled, (state, action) => {
            state.loading = false;
            state.ctcOfferedData = action.payload
        })
        builder.addCase(fetchCtcOffered.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default ctcOfferedSlice.reducer