import { MailIcon } from "lucide-react";
import { BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "../ui/alert-dialog";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../ui/tooltip";

import { Button } from "../ui/button";
import { useDispatch } from "react-redux";
import { deleteConnectionReq } from "../../redux/features/removeInvitaionSlice"

const CompanyConnectedActions = ({ companyName, connectionId, pocId, pocName, pocEmail }) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteConnectionReq(connectionId));
    };

    return (
        <div onClick={(e) => e.stopPropagation()} className="flex items-center gap-x-4">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Link to={`mailto:${pocEmail}`} className="flex justify-center items-center">
                            <MailIcon className="cursor-pointer" size={20} />
                        </Link>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Send Mail</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
                <Tooltip>
                    <AlertDialog onClick={(e) => e.stopPropagation()}>
                        <TooltipTrigger asChild>
                            <AlertDialogTrigger className="flex justify-center items-center">
                                <BiTrash className="cursor-pointer" size={20} />
                            </AlertDialogTrigger>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Delete Connection</p>
                        </TooltipContent>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you sure you want to delete this connection?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will permanently delete your connection with {companyName}.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <Button onClick={handleDelete} variant="primary" className="shadow-md">
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

export default CompanyConnectedActions;
