import { Skeleton } from "../ui/skeleton"

const DriveDetailLoading = () => {
    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center gap-x-6">
                <Skeleton className="w-2/3 h-28" />
                <Skeleton className="w-1/3 h-28" />
            </div>
            <div className="flex items-center gap-x-6">
                {
                    [1, 2, 3, 4].map((_, i) => (

                        <Skeleton key={i} className="w-40 h-40 rounded-full" />
                    ))
                }
            </div>
            <div className="flex items-center gap-x-6">
                {
                    [1, 2, 3].map((_, i) => (
                        <Skeleton key={i} className="w-1/3 h-60" />
                    ))
                }
            </div>
        </div>
    )
}
export default DriveDetailLoading