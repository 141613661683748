import CustomImage from "../../CustomImage";
import { Button } from "../../ui/button";
import CompanyConnectedActions from "../CompanyConnectedActions";
import CompanyInvitationModal from "../CompanyInvitationModal";
import CompanyModal from "../CompanyModal";
import CompanyViewInvitation from "../CompanyViewInvitation";
import CompanyWithdraw from "../CompanyWithdraw";
import DeleteInviteModal from "../DeleteInviteModal";

const statusEnum = {
    APPROACHED_BY_COLLEGE: 'Approached',
    APPROACHED_BY_COMPANY: "Approached By Company",
    ACCEPTED: 'Accepted',
    REJECTED_BY_COLLEGE: "Rejected By College",
    REJECTED_BY_COMPANY: "Rejected By Company",
    NOT_CONNECTED: null
}

export const companyDataColumns = [
    {
        accessorKey: "logo",
        header: "Logo",
        cell: ({ row }) => {
            const company = row.original;
            return  <CustomImage className="w-12 h-12 object-contain rounded-full" src={company.logoUrl} alt="Company Image" section="company"/>
        }
    },
    {
        accessorKey: "name",
        header: "Name",
    },
    {
        accessorKey: "typeOfHires",
        header: "Hiring From",
        cell: ({ row }) => {
            if (row.original.typeOfHires.length < 3) {
                return row.original.typeOfHires.join(", ")
            }
            return <span className="flex">{row.original.typeOfHires.slice(0, 2).join(", ")}, <p className="underline text-neutral-600">+{row.original.typeOfHires.length - 2} more</p></span>
        }
    },
    {
        accessorKey: "location",
        header: "Location",
    },
    {
        id: "invitation",
        cell: ({ row }) => {
            const company = row.original

            const handleInviteButtonClick = (event) => {
                event.stopPropagation()
            }
            switch (company.status) {
                case statusEnum.APPROACHED_BY_COMPANY:
                    return (
                        <CompanyViewInvitation connectionId={company.connectionId} />
                    );

                case statusEnum.APPROACHED_BY_COLLEGE:
                    return (
                        <CompanyWithdraw connectionId={company.connectionId} />
                    );

                case statusEnum.ACCEPTED:
                    return (
                        <Button variant="primary" size="md" className="bg-[#195D30] shadow-md text-base hover:bg-[#195D30]/70 ">
                            Connected
                        </Button>
                    );

                case statusEnum.REJECTED_BY_COLLEGE:
                    return (
                        <DeleteInviteModal connectionId={company.connectionId} />
                    );

                case statusEnum.REJECTED_BY_COMPANY:
                    return (
                        <Button variant="primary" className="shadow-md text-base">
                            Invitation Rejected
                        </Button>
                    );

                case statusEnum.NOT_CONNECTED:
                    return (
                        <CompanyInvitationModal companyId={company.id} />
                    );

                default:
                    return null;
            }
        },
    },
    {
        id : "actions",
        header: "Action",
        cell : ({row}) => {
            const company = row.original
            if(company.status === statusEnum.ACCEPTED){
                return <CompanyConnectedActions companyName={company.name} connectionId={company.connectionId} pocName={company.pocName} pocEmail={company.pocEmail} pocId={company.pointOfContactId}  />
            }
        }
    },
]