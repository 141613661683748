import { createSlice } from "@reduxjs/toolkit"


export const studentViewSlice = createSlice({
    name: 'View Slice',
    initialState: {
        view: 'gridView',
        limit: 10,
        offset: 0,
        page: 0,
        noOfRows: 10,
        searchName: "",
    },
    reducers: {
        updateView: (state, action) => {
            state.view = action.payload
        },
        updateLimit: (state, action) => {
            state.limit = action.payload
        },
        updateOffset: (state, action) => {
            state.offset = action.payload
        },
        updatePage: (state, action) => {
            state.page = action.payload
        },
        updateNoRows: (state, action) => {
            state.noOfRows = action.payload
        },
        updateSearchName: (state, action) => {
            state.searchName = action.payload
        },
    }
})

export const { updateView, updateLimit, updateOffset, updatePage, updateNoRows, updateSearchName } = studentViewSlice.actions
export default studentViewSlice.reducer