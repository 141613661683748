import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const createDriveFromResponse = createAsyncThunk('createDriveFromResponse', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/job/create-drive`, obj.body)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const driveFromJobResponseSlice = createSlice({
    name: 'driveFromJobResponseSlice',
    initialState: {
        loading: false,
        success: false,
        error: null,
        message : null
    },
    reducers : {
        resetCreateDriveSuccess : (state) =>{
            state.success = false
            state.message = null
        },
        resetCreateDriveError : (state) =>{
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createDriveFromResponse.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(createDriveFromResponse.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.message = action.payload.message
        })
        builder.addCase(createDriveFromResponse.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})

export const {resetCreateDriveSuccess,resetCreateDriveError} = driveFromJobResponseSlice.actions
export default driveFromJobResponseSlice.reducer