import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const startNextRound = createAsyncThunk('startNextRound', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.patch(`${process.env.REACT_APP_BACKEND_URL}/api/job/update-drive-round/${driveId}`);
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const moveToNextRoundSlice = createSlice({
    name: 'Next Round Slice',
    initialState: {
        loading: false,
        error: null,
        success: null,
        message: null,
    },
    reducers: {
        resetNextRoundDriveSuccess: (state) => {
            state.success = false
            state.message = null
        },
        resetNextRoundDriveError: (state) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(startNextRound.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(startNextRound.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.message
            state.success = true;
        })
        builder.addCase(startNextRound.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetNextRoundDriveSuccess, resetNextRoundDriveError } = moveToNextRoundSlice.actions
export default moveToNextRoundSlice.reducer