import { DownloadIcon, Eye, Trash2 } from "lucide-react"
import { Icon, Icons } from "../ui/icon"
import { Button } from "../ui/button"
import { useEffect, useState } from "react";
import axios from "axios";
import { Buffer } from 'buffer';

const signedUrlToDataUrl = async (file) => {
    return window.URL.createObjectURL(await axios.get(file.signedUrl, {
        responseType: 'arraybuffer'
        }).then((response)=> {
        const buffer = Buffer.from(response.data, 'binary')
        const blob = new Blob([buffer],  {type: 'application/pdf'});
        return blob;
        }), {type: "application/pdf"});
}


const DocumentCard = ({document}) => {
    const title = document.filename.split("_")[1];
    const [fileDataUrl, setFileDataUrl] = useState(undefined);

    useEffect(() => {
        if(fileDataUrl === undefined) {
            signedUrlToDataUrl(document).then((url) => setFileDataUrl(url)).catch((e) => setFileDataUrl(null));
        }
    }, [fileDataUrl])

    return (
        <div className="flex items-center justify-between bg-cardBgColor/35 border py-2 px-3 shadow-md rounded-md">
            <p>{title}</p>
            <div className="flex items-center gap-x-0">
                {
                    fileDataUrl && <>
                        <Button variant="ghost" size="icon" className="rounded-full">
                            <a href={fileDataUrl} target="_blank" rel="noopener noreferrer">
                                <Icon icon={Icons.VIEW} className="bg-onCardBg"/>
                            </a>
                        </Button>
                        <Button variant="ghost" size="icon" className="rounded-full">
                            <a href={fileDataUrl} download={title}>
                                <Icon icon={Icons.DOWNLOAD} className="bg-onCardBg"/>
                            </a>
                        </Button>
                    </>
                    
                }
                
                {/* <Button variant="ghost" size="icon" className="rounded-full">
                    <Icon icon={Icons.DELETE}/>
                </Button> */}
            </div>
        </div>
    )
}
export default DocumentCard