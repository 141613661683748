import { Search } from "lucide-react"
import { Input } from "../ui/input"
import CreateAnnouncementDialog from "./CreateAnnouncementDialog"
import DriveDetailsHeader from "./DriveDetailsHeader"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDriveAnnouncements } from "../../redux/features/drive-announcements/getDriveAnnouncementSlice"
import AnnouncementTable from "./DriveTable/AnnouncementTable"
import { AnnouncementColumns } from "./DriveTable/AnnouncementColumns"
import { useParams } from "react-router-dom"
import DriveDetailLoading from "./DriveDetailLoading"
import { resetAnnouncementSuccessMsg } from "../../redux/features/drive-announcements/createAnnouncementSlice"
import { toast } from "sonner"
import { getParicularDriveDetail } from "../../redux/features/getParticularDriveDetailSlice"
import { Button } from "../ui/button"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog"
import { getDriveAnnouncementsDetails } from "../../redux/features/drive-announcements/getAnnouncementDetails"
import AnnouncementDetailLoading from "./AnnouncementDetailLoading"
import { DataTable } from "../reusable/table/DataTable"
const DriveAnnouncements = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const handleSearchChange = () => {

    }
    const { loading, driveAnnouncements } = useSelector((state) => state.getDriveAnnouncementReducer)
    const { loading: createAnnouncementLoading, successMsg, error } = useSelector((state) => state.createDriveAnnouncementReducer)
    const { loading: announcementDetailLoading, announcementDetail, error: announcementDetailError } = useSelector((state) => state.announcementDetailReducer)

    useEffect(() => {
        dispatch(getDriveAnnouncements(id))
        dispatch(getParicularDriveDetail({ id: id }))
        if (successMsg) {
            toast.success(successMsg)
            dispatch(resetAnnouncementSuccessMsg())
        }
        if (error) {
            toast.error(error)
        }
    }, [successMsg, error])


    const [open, setOpen] = useState(false)

    const handleAnnouncementDetails = (row) => {
        setOpen(true)
        dispatch(getDriveAnnouncementsDetails(row.id))
    }
    return (
        <div className="flex flex-col mt-3 px-4">
            {
                loading || createAnnouncementLoading ? <DriveDetailLoading /> :

                    <>
                        <DriveDetailsHeader hideAnnouncementButton={true}/>
                        <div className="flex items-center gap-x-6 mt-4">
                            <h1 className="text-2xl font-bold">Announcements</h1>
                            <div className="flex bg-searchBgColor items-center w-1/3 justify-between border rounded-lg px-2 ">
                                <Input onChange={handleSearchChange} className="font-light bg-transparent text-base border-none" placeholder="Search announcements history" />
                                <Search size={20} />
                            </div>
                            <CreateAnnouncementDialog />
                        </div>
                        {
                            driveAnnouncements && <DataTable hasClick={true} hasPagination={false} data={driveAnnouncements} columns={AnnouncementColumns} onClickRoute={handleAnnouncementDetails} />
                        }

                        <Dialog open={open} onOpenChange={setOpen}>
                            <DialogContent>
                                <DialogHeader>
                                    {
                                        announcementDetailLoading ? <AnnouncementDetailLoading /> :
                                            <>

                                                <DialogTitle className="text-xl">{announcementDetail?.title}</DialogTitle>
                                                <DialogDescription className="mt-3 flex flex-col space-y-3">
                                                    {announcementDetail?.subtitle}
                                                </DialogDescription>
                                            </>
                                    }
                                </DialogHeader>
                            </DialogContent>
                        </Dialog>
                    </>
            }

        </div>
    )
}
export default DriveAnnouncements