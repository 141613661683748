import CustomImage from '../../CustomImage'
import { Icon, Icons } from '../../ui/icon'

export const networkColumns = [
    {
        accessorKey: "company_logo",
        header: "Logo",
        cell: ({ row }) => {
            const connection = row.original
            return <CustomImage className="w-12 h-12 object-contain rounded-full" src={connection.company_logo} alt="" section="company"/>
        }
    },
    {
        accessorKey: "company_name",
        header: "Name",
    },
    {
        accessorKey: "contact_name",
        header: "Point of Contact",
    },
    {
        accessorKey: "email",
        header: "Email",
    },
    {
        accessorKey: "phoneNumber",
        header: "Contact Number",
    },
    {
        header: "Actions",
        cell: ({ row }) => {
            const connection = row.original
            return <a href={`mailto:${connection.email}`}>
                <Icon icon={Icons.MAIL} />
            </a>
        }
    },
]