import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";

const CompanyPercentagePlacement = () => {

  const { percentageWisePlaced } = useSelector((state) => state.percentageWisePlaced)

  const chartData = {
    labels: percentageWisePlaced.map((company) => company.companyName),
    datasets: [
      {
        label: "Percentage",
        data: percentageWisePlaced.map((company) => company.percentage),
        backgroundColor: ["#FFD76E", "#E37C7C", "#9F629A", "#FF9C08"],
      },
    ],
  };
  return (
    <div>
      <div className="rounded-lg shadow-lg px-4 py-2">
        <h2 className="text-primaryTextColor text-lg font-semibold">Company Wise Placement Statistics</h2>
        <Pie data={chartData}></Pie>
      </div>
    </div>
  )
}
export default CompanyPercentagePlacement