export const TargetPoolColumns = [
    {
        id: "srno",
        header: "Sr No.",
        cell: ({ row }) => {
            const member = row.index
            return <p>{member + 1}</p>
        }
    },
    {
        accessorKey: "branch",
        header: "Branch"
    },
    {
        accessorKey: "hiring_number",
        header: "Hiring",
        cell: ({ row }) => {
            return <p>{new Intl.NumberFormat(navigator.language).format(row.original.hiring_number)}</p>
        }
    },
]