import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const updateStudentStatusApplication = createAsyncThunk('updateStudentStatusApplication', async (body, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.patch(`${process.env.REACT_APP_BACKEND_URL}/api/job/drives/${body.applicationId}/update-status`, body);
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const updateStudentApplicationStatusSlice = createSlice({
    name: 'studentPlacedCount',
    initialState: {
        loading: false,
        error: null,
        success: null,
        message: null,
    },
    reducers: {
        resetStatusSuccess: (state) => {
            state.success = false
            state.message = null
        },
        resetStatusError: (state) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateStudentStatusApplication.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateStudentStatusApplication.fulfilled, (state, action) => {
            state.loading = false;
            state.message = action.payload.message
            state.success = true;
        })
        builder.addCase(updateStudentStatusApplication.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export const { resetStatusSuccess, resetStatusError } = updateStudentApplicationStatusSlice.actions
export default updateStudentApplicationStatusSlice.reducer