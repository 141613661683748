import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetEmailSent, resetLoginError, userSignup } from '../../redux/features/loginSlice';
import { handleSnackbar } from '../../redux/features/snackbarSlice';
import { fetchAllStudents } from '../../redux/features/studentSlice';
import { addMember, resetAddMemberSuccess } from '../../redux/features/addMemberSlice';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { toast } from 'sonner';
import ButtonLoading from '../reusable/loaders/button-loading';


const generatePassword = (length = 12) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

export default function AddMember({ onCreation = () => { } }) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [designation, setDesignation] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const { user } = useSelector((state) => state.loginReducer)
  const { success: addMemberSuccess, error: addMemberError, loading } = useSelector((state) => state.addMember)
  const { students } = useSelector((state) => state.getAllMembers)

  const dispatch = useDispatch()
  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setPassword(newPassword);
  };

  const handleClose = () => {
    setOpen(false);
    setName('')
    setEmail('')
    setPassword('')
    setDesignation('')
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleAddMember = () => {
    if (!email || !name || !password || !designation) {
      toast.warning("Field cannot be empty")
      return;
    }

    const body = {
      email,
      designation,
      password,
      name,
    }
    dispatch(addMember({ body, type: 'add-member' }))
    handleClose()
  };

  useEffect(() => {
    if (addMemberError) {
      toast.error(addMemberError)
      dispatch(resetLoginError())
    } else if (addMemberSuccess) {
      toast.success("Member Added successfully")
      dispatch(resetAddMemberSuccess());
      onCreation();
    }

  }, [addMemberError, addMemberSuccess])

  if (!user?.adminRights) {
    return <></>
  }
  return (
    <div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="primary" className="px-2 rounded-3xl text-md" >+ Add Member</Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[500px] bg-[#F5F4F4]">
          <DialogHeader>
            <DialogTitle>Add Member</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-y-4">
            {/* Name Field */}
            <div className="flex items-center">
              <Label htmlFor="name" className="w-24">
                Name
              </Label>
              <Input
                onChange={(e) => setName(e.target.value)}
                id="name"
                value={name}
                className="flex-1 ml-2"
              />
            </div>

            {/* Email Field */}
            <div className="flex items-center">
              <Label htmlFor="email" className="w-24">
                Email
              </Label>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                value={email}
                className="flex-1 ml-2"
              />
            </div>

            {/* Designation Field */}
            <div className="flex items-center">
              <Label htmlFor="designation" className="w-24">
                Designation
              </Label>
              <Input
                onChange={(e) => setDesignation(e.target.value)}
                id="designation"
                value={designation}
                className="flex-1 ml-2"
              />
            </div>

            {/* Password Field */}
            <div className="flex items-center">
              <Label htmlFor="password" className="w-24">
                Password
              </Label>
              <div className="flex-1 ml-2">
                <Input type="password" id="password" value={password} />
              </div>
            </div>
            <Button onClick={handleGeneratePassword} variant="secondary" className="w-full mt-2">
              Generate Password
            </Button>
          </div>

          <DialogFooter>
            {/* <Button onClick={handleClose} type="submit" variant="primary">Cancel</Button> */}
            <Button onClick={handleAddMember} type="submit" className="rounded-3xl w-[4rem] text-md" variant="primary">{loading ? <ButtonLoading /> : "Add"}</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}