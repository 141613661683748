const DriveDescription = ({ description, eligibilityCriteria, ctcMin, ctcMax, pocName, pocEmail }) => {
    return (
        <div className="my-6 grid grid-cols-3 gap-x-6">
            <div className="flex flex-col gap-y-2">
                <h2 className="font-bold text-2xl">Description</h2>
                <div className="border rounded-lg p-4 shadow-xl h-full overflow-y-auto max-h-48">
                    <p className="leading-relaxed text-justify text-[0.9rem]" style={{ wordSpacing: "0.1em" }}>{description}</p>
                </div>
            </div>
            <div className="flex flex-col gap-y-2">
                <h2 className="font-bold text-2xl">Eligibility Criteria</h2>
                <div className="border rounded-lg p-4 shadow-xl h-full overflow-y-auto max-h-48">
                <p className="leading-relaxed text-justify text-[0.9rem]">{eligibilityCriteria}</p>
                </div>
            </div>

            <div className="flex flex-col gap-y-2">
                <div className="flex items-stretch gap-x-2">
                    <div className="border rounded-lg p-4 shadow-xl flex-1">
                        <h2 className="font-bold text-xl">CTC Range</h2>
                        <p>{ctcMin} - {ctcMax} LPA</p>
                    </div>
                    <div className="border rounded-lg p-4 shadow-xl flex-1">
                        <h2 className="font-bold text-xl">Locations</h2>
                        <p></p>
                    </div>
                </div>
                <div className="border rounded-lg p-4 shadow-xl flex-grow">
                    <h2 className="font-bold text-xl">Point of Contact</h2>
                    <p>{pocName}</p>
                    <p>{pocEmail}</p>
                </div>
            </div>
        </div>
    );
};

export default DriveDescription;
