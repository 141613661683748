import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from '../../headersService'
export const addMember = createAsyncThunk('addMember', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/tpo?type=${obj.type}`, obj.body)
        return data
    } catch (error) {
        console.log(rejectWithValue(error))
        return rejectWithValue(error)
    }
})

export const addMemberSlice = createSlice({
    name: 'postdrive',
    initialState: {
        loading: false,
        success: false,
        error: null
    },
    reducers: {
        resetAddMemberSuccess: (state) => {
            state.success = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addMember.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(addMember.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
        })
        builder.addCase(addMember.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetAddMemberSuccess } = addMemberSlice.actions
export default addMemberSlice.reducer