import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from '../../../headersService'

export const updateMyProfile = createAsyncThunk('updateMyProfile', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.patch(`${process.env.REACT_APP_BACKEND_URL}/api/tpo/my-profile`, obj)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const updateTpoProfileSlice = createSlice({
    name: 'Update TPO Profile',
    initialState: {
        loading: false,
        error: null,
        success: null
    },
    reducers: {
        resetUpdateProfileSuccess: (state) => {
            state.success = null
        },
        resetUpdateProfileError: (state) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateMyProfile.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateMyProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.success = "Profile updated successfully"
        })
        builder.addCase(updateMyProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export const { resetUpdateProfileSuccess, resetUpdateProfileError } = updateTpoProfileSlice.actions
export default updateTpoProfileSlice.reducer