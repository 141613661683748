import { IoArrowBackCircle } from "react-icons/io5"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Badge } from "../ui/badge"
import CustomImage from "../CustomImage"

const CompanyDetailsHeader = () => {

    const { company } = useSelector((state) => state.getCompanyDetail)
    const navigate = useNavigate()
    return (
        <div className="grid grid-cols-3 space-x-6">
            <div className="bg-cardBgColor/35 relative col-span-2 py-4 px-6 shadow-lg rounded-lg border">
                <div onClick={() => navigate(-1)}>
                    <IoArrowBackCircle size={50} className="text-primaryTextColor cursor-pointer hover:text-primaryTextColor/80 absolute -left-5 -top-2" />
                </div>
                <div className="flex items-center gap-x-4 w-full ">
                    <div>
                        <CustomImage className="w-20 h-20 rounded-full object-contain" src={company?.logoUrl} alt="" section="company" />
                    </div>

                    <div className="flex flex-col space-y-1 flex-1">
                        <h1 className="text-2xl font-semibold">{company?.name}</h1>
                        <div className="flex items-center">
                            <p className="">
                                {company?.address}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow-lg rounded-lg border py-4 px-6">
                <h1 className="font-semibold text-primaryTextColor text-lg">Hiring From</h1>
                <div className="mt-2 flex flex-wrap gap-2">
                    {
                        company?.typeOfHires?.map((branch, i) => {
                            return <div key={i} className="bg-[#A0A0A0]/10 border rounded-2xl px-2 py-1 shadow-md text-black text-sm">{branch}</div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default CompanyDetailsHeader