import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

const CtcStats = () => {

    const { ctcOfferedData } = useSelector((state) => state.ctcOffered)

    const chartData = {
        labels: ctcOfferedData.map((offer) => offer.companyName),
        datasets: [
            {
                label: "CTC offered(in lakhs)",
                data: ctcOfferedData.map((offer) => offer.ctcOffered),
                backgroundColor: ["#A2DB00"],
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "CTC offered in(in lakhs)",
                },
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Companies",
                },
            },
        },
    };
    return (
        <div>
            <div className="rounded-lg shadow-lg px-4 py-2">
                <h2 className="text-primaryTextColor text-lg font-semibold">CTC Stats</h2>
                <Bar data={chartData} options={options}></Bar>
            </div>
        </div>
    )
}
export default CtcStats