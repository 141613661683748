import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchStudentDocs = createAsyncThunk('fetchStudentDocs', async (studentId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/student/getalldocs/${studentId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const studentDocsSlice = createSlice({
    name: 'genderPlaced',
    initialState: {
        loading: false,
        documents: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStudentDocs.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchStudentDocs.fulfilled, (state, action) => {
            state.loading = false;
            state.documents = action.payload
        })
        builder.addCase(fetchStudentDocs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default studentDocsSlice.reducer