import activeHomeIcon from '../assets/sidebarIcons/activeHomeIcon.png'
import activeStudentIcon from '../assets/sidebarIcons/activeStudentIcon.png'
import activeCompaniesIcon from '../assets/sidebarIcons/activeCompaniesIcon.png'
import activeDriveIcon from '../assets/sidebarIcons/activeDriveIcon.png'
import activeReportsIcon from '../assets/sidebarIcons/activeReportsIcon.png'
import sidebarOpenActiveHomeIcon from '../assets/sidebarIcons/sidebarOpenActiveHomeIcon.png'
import sidebarOpenActiveStudentIcon from '../assets/sidebarIcons/sidebarOpenActiveStudentIcon.png'
import sidebarOpenUnselectedCompaniesIcon from '../assets/sidebarIcons/sidebarOpenUnselectedCompaniesIcon.png'
import sidebarOpenUnselectedDriveIcon from '../assets/sidebarIcons/sidebarOpenUnselectedDrivesIcon.png'
import sidebarOpenUnselectedReportsIcon from '../assets/sidebarIcons/sidebarOpenUnselectedReportsIcon.png'
import unSelectedCompaniesIcon from '../assets/sidebarIcons/unSelectedCompaniesIcon.png'
import unSelectedHomeIcon from '../assets/sidebarIcons/unSelectedHomeIcon.png'
import unSelectedDriveIcon from '../assets/sidebarIcons/unSelectedDriveIcon.png'
import unSelectedReportsIcon from '../assets/sidebarIcons/unSelectedReportsIcon.png'
import unSelectedStudentIcon from '../assets/sidebarIcons/unSelectedStudentIcon.png'
export const sidebarItems = [
    {
        label: 'Home',
        link: '/',
        activeIcon: activeHomeIcon,
        unSelectedIcon: unSelectedHomeIcon,
        sidebarOpenSelectedIcon: sidebarOpenActiveHomeIcon,
        sidebarOpenUnselectedIcon: sidebarOpenActiveHomeIcon,
    },
    {
        label: 'Companies',
        link: '/companies',
        activeIcon: activeCompaniesIcon,
        unSelectedIcon: unSelectedCompaniesIcon,
        sidebarOpenSelectedIcon: sidebarOpenUnselectedCompaniesIcon,
        sidebarOpenUnselectedIcon: sidebarOpenUnselectedCompaniesIcon,
    },
    {
        label: 'Students',
        link: '/student',
        activeIcon: activeStudentIcon,
        unSelectedIcon: unSelectedStudentIcon,
        sidebarOpenSelectedIcon: sidebarOpenActiveStudentIcon,
        sidebarOpenUnselectedIcon: sidebarOpenActiveStudentIcon,
    },
    {
        label: 'Drives',
        link: '/drive/on-campus',
        activeIcon: activeDriveIcon,
        unSelectedIcon: unSelectedDriveIcon,
        sidebarOpenSelectedIcon: sidebarOpenUnselectedDriveIcon,
        sidebarOpenUnselectedIcon: sidebarOpenUnselectedDriveIcon,
    },
    {
        label: 'Reports',
        link: '/reports',
        activeIcon: activeReportsIcon,
        unSelectedIcon: unSelectedReportsIcon,
        sidebarOpenSelectedIcon: sidebarOpenUnselectedReportsIcon,
        sidebarOpenUnselectedIcon: sidebarOpenUnselectedReportsIcon,
    },
];