import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from '../../headersService'

export const fetchCompanyIndustry = createAsyncThunk('fetchCompanyIndustry', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/company/dropdown/items?type=${obj.type}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const companyIndustry = createSlice({
    name: 'companyIndustry',
    initialState: {
        loading: false,
        companyIndustryType: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyIndustry.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchCompanyIndustry.fulfilled, (state, action) => {
            state.loading = false;
            state.companyIndustryType = action.payload
        })
        builder.addCase(fetchCompanyIndustry.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default companyIndustry.reducer