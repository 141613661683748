import * as React from "react";
import { useRef } from "react";
import { AiFillFilePdf, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  companiesPointOfContact,
  scheduleDriveCompaniesList,
} from "../../redux/features/ScheduleDriveCompaniesListSlice";
import { useState, useEffect } from "react";
import {
  addDrives,
  resetAddDriveSuccess,
  resetDriveError,
} from "../../redux/features/addDriveSlice";
import { handleSnackbar } from "../../redux/features/snackbarSlice";
import { getAllCollaborators } from "../../redux/features/getCollaborators";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { File, Upload, X } from "lucide-react";
import { Checkbox } from "../ui/checkbox";
import MultipleSelector from "../ui/multi-selector";
import { toast } from "sonner";

const ScheduleDrive = ({ children }) => {
  const { user } = useSelector((state) => state.loginReducer);
  const [open, setOpen] = useState(false);
  const [companyChosen, setCompanyChosen] = useState("");
  const [collaborator, setCollaborator] = useState([]);
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [note, setNote] = useState("");
  const { scheduleCompanies, poc } = useSelector(
    (state) => state.scheduleDriveCompanies
  );

  const { collaborators } = useSelector((state) => state.collaboratorsReducer);
  const { success, error } = useSelector((state) => state.postDrives);

  const [defaultOptions, setDefaultOptions] = useState();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const uploadFile = (e) => {
    const files = e.target.files[0];
    setSelectedFile(files);
  };

  const handleCompanyChange = (value) => {
    setCompanyChosen(value);
    dispatch(companiesPointOfContact({ companyId: value }));
  };

  const handleCollaboratos = (event) => {
    setCollaborator(event.target.value);
  };

  const filteredCompanies = scheduleCompanies.filter(
    (company) => Object.keys(company).length > 0
  );

  const handleClose = () => {
    setOpen(false);
    setCompanyChosen("");
    setSelectedFile(null);
    setCollaborator([]);
  };

    useEffect(() => {
        dispatch(scheduleDriveCompaniesList())
        dispatch(getAllCollaborators())
    }, [])

  useEffect(() => {
    setDefaultOptions(
      collaborators.map((user) => ({
        label: user.name,
        value: user.id,
      }))
    );
  }, [collaborators]);

  const date = new Date();
  const year = date.getFullYear();
  const handlePostDrive = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("companyId", companyChosen);
    formData.append("note", note);
    formData.append("pointOfContact", poc?.contact_id);
    formData.append("label", "drive-request");

    if (!companyChosen || !note) {
      toast.warning("Fields Cannot be empty");
      return;
    }
    collaborator.forEach((collab) => {
      formData.append("collaborators[]", collab.value);
    });
    dispatch(addDrives({ body: formData }));
    handleClose();
  };

  useEffect(() => {
    if (success) {
      toast.success("Drive Request sent successfully");
      dispatch(resetAddDriveSuccess());
    }
    if (error) {
      toast.error("Something went wrong");
      dispatch(resetDriveError());
    }
  }, [success, error]);

  return (
<div>
<Dialog open={open} onOpenChange={setOpen}>
  <DialogTrigger>{children}</DialogTrigger>
  <DialogContent className="bg-[#F5F4F4]">
    <DialogHeader>
      <DialogTitle className="text-xl">Schedule Drive</DialogTitle>
      <DialogDescription className="mt-3 flex flex-col space-y-4">
       
        <div className="grid grid-cols-[160px_1fr] gap-x-2 items-center">
          <Label
            className="text-black text-base"
            htmlFor="company-name"
          >
            Company Name
          </Label>
          <Select
            name="company-name"
            onValueChange={(value) => handleCompanyChange(value)}
            defaultValue={companyChosen}
          >
            <SelectTrigger className="w-full bg-white">
              <SelectValue placeholder="Select company to schedule a drive" />
            </SelectTrigger>
            <SelectContent>
              {filteredCompanies.length === 0 ? (
                <SelectGroup>
                  <SelectLabel>
                    No eligible college found for starting a drive
                  </SelectLabel>
                </SelectGroup>
              ) : (
                filteredCompanies.map((company) => (
                  <SelectItem key={company.id} value={company.id}>
                    {company.name}
                  </SelectItem>
                ))
              )}
            </SelectContent>
          </Select>
        </div>

        <div className="grid grid-cols-[160px_1fr] gap-x-2 items-center">
          <Label className="text-black text-base" htmlFor="poc">
            Point of Contact
          </Label>
          <Input
            name="poc"
            className="w-full"
            placeholder="Contact Name"
            value={poc != null ? poc?.contact_name : ""}
          />
        </div>

        <div className="grid grid-cols-[160px_1fr] gap-x-2 items-center">
          <Label
            className="text-black text-base"
            htmlFor="collaborator"
          >
            Select Collaborators
          </Label>
          <MultipleSelector
            value={collaborator}
            onChange={setCollaborator}
            defaultOptions={defaultOptions}
            placeholder="Select Collaborators..."
            badgeClassName="bg-primaryTextColor text-white hover:bg-primaryTextColor/80"
            className="bg-white"
            emptyIndicator={
              <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                no results found.
              </p>
            }
          />
        </div>

        <div className="flex flex-col gap-y-2">
          <Label htmlFor="note" className="text-black mb-2 text-base">
            Add a Note
          </Label>
          <Textarea
            onChange={(e) => setNote(e.target.value)}
            name="note"
            rows={5}
            placeholder="Enter your note..."
          />
        </div>
      </DialogDescription>
    </DialogHeader>

    <DialogFooter className="flex items-center sm:justify-between w-full">
      <input
        ref={fileRef}
        type="file"
        id="file1"
        hidden
        onChange={uploadFile}
      />
      {selectedFile ? (
        <Button
          onClick={() => fileRef.current.click()}
          variant="outlined"
          className="py-1 relative rounded-2xl px-6"
          type="submit"
        >
          <File size={15} className="mr-1" />
          {selectedFile.name.substring(0, 20)}...
          <div
            onClick={() => setSelectedFile(null)}
            className="absolute bg-black rounded-full w-4 h-4 flex items-center justify-center p-1 right-0 top-0"
          >
            <X size={20} className="text-white" />
          </div>
        </Button>
      ) : (
        <Button
          onClick={() => fileRef.current.click()}
          variant="outlined"
          className="rounded-3xl"
          type="submit"
        >
          <Upload size={15} className="mr-1" />
          Upload File
        </Button>
      )}
      <Button
        variant="primary"
        onClick={handlePostDrive}
        className="rounded-3xl shadow-md"
        type="submit"
      >
        Send Invite
      </Button>
    </DialogFooter>
  </DialogContent>
</Dialog>

    </div>
  );
};
export default ScheduleDrive;
