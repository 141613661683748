import { Skeleton } from "../ui/skeleton"

const MemberDetailsLoading = () => {
    return (
        <div className="flex flex-col gap-y-4">
            {
                [1,2,3].map((_,i) => {
                    return <Skeleton key={i} className="w-full h-14" />
                })
            }
        </div>
    )
}
export default MemberDetailsLoading