import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import CustomImage from "../CustomImage";

const InviteCompanies = ({ companyData, invite, title }) => {
  
  const navigate = useNavigate()
  return (
    <div className="rounded-2xl border shadow-lg px-4 py-6 row-span-2 col-span-1 h-[300px]">
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-primaryTextColor text-xl">{title}</h1>
        <Button onClick={() => navigate("/companies")} variant="primary" className="py-0" size="sm">
          See All
        </Button>
      </div>
      <div className="flex flex-row flex-wrap mt-4 h-full overflow-clip">
        {
          companyData?.map((item,i) => {
            return (
              <div key={i} className="w-1/4 h-1/2 flex flex-col space-y-1 items-center cursor-pointer" onClick={() => navigate(`/companies/${item.id}`)} >
                <CustomImage className="w-12 h-12 rounded-full object-contain " src={item.logoUrl} alt="Company Image" section="company" />
                <p className="text-center w-full overflow-hidden text-ellipsis text-[12px]">{item.name}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  )
};
export default InviteCompanies;
