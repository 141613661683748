import { formatDateWithTime } from "../../../../src/utils/format-date"

export const AnnouncementColumns = [
    {
        accessorKey: "title",
        header: "Announcement Title",
    },
    {
        accessorKey: "target_audience",
        header: "Target Audience",
    },
    {
        accessorKey: "created_at",
        header: "Date of Announcement",
        cell : ({row}) => {
            return <p>{formatDateWithTime(row.original.created_at)}</p>
        }
    },
]