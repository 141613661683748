import { formatDate } from "../../../../src/utils/format-date";
import CustomImage from "../../CustomImage";

export const DriveColumns = [
    {
        accessorKey: "logo",
        header: "Logo",
        cell: ({ row }) => {
            const drive = row.original;
            return <CustomImage className="w-12 h-12 object-contain rounded-full" src={drive.logo} alt="" section="company"   />
        }
    },
    {
        accessorKey: "company_name",
        header: "Company Name",
    },
    {
        accessorKey: "start_date",
        header: "Start Date",
        cell : ({row}) => {
            return <p>{formatDate(row.original.start_date)}</p>
        }
    },
    {
        accessorKey: "end_date",
        header: "End Date",
        cell : ({row}) => {
            return <p>{formatDate(row.original.end_date)}</p>
        }
    },
    {
        accessorKey: "current_round",
        header: "Current Round",
    },
]