import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import profilePic from "../../assets/profilePic.png";
import { Skeleton } from "../ui/skeleton";
import { resetUpdateProfileError, resetUpdateProfileSuccess, updateMyProfile } from '../../../src/redux/features/tpo/updateTpoProfileSlice';
import ButtonLoading from '../reusable/loaders/button-loading';
import { toast } from 'sonner';
import { fetchMyProfile } from "../../redux/features/tpo/myProfileSlice";

const Profile = () => {
  const { loading, profileDetails } = useSelector((state) => state.myProfileReducer);
  const { loading: updateLoading, success, error } = useSelector((state) => state.updateTpoProfileReducer)
  const { user } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    alternateEmail: "",
    dateOfBirth: "",
  });

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
  }

  const handleUpdateProfile = () => {
    dispatch(updateMyProfile({ ...userInfo, dateOfBirth: new Date(userInfo?.dateOfBirth)?.toLocaleDateString('en-CA') }));
  }

  const handleSuccess = () => {
    toast.success(success)
    dispatch(resetUpdateProfileSuccess())
  }
  const handleError = () => {
    toast.success(error)
    dispatch(resetUpdateProfileError())
  }
  useEffect(() => {
    if (profileDetails) {
      setUserInfo({
        name: profileDetails?.name,
        email: profileDetails?.email,
        phoneNumber: profileDetails?.phone_number?.substring(3),
        alternateEmail: profileDetails?.alternateEmail,
        dateOfBirth: profileDetails?.dateOfBirth
      });

      if (profileDetails?.dateOfBirth) {
        const formattedDate = moment(profileDetails.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DD');
        setDate(formattedDate);
      }
    }
  }, [profileDetails]);


  useEffect(() => {
    if (success) {
      handleSuccess()
    }
    if (error) {
      handleError()
    }
    dispatch(fetchMyProfile())
  }, [success, error])

  return (
    <>
      <div className="shadow-xl rounded-tr-lg rounded-br-lg rounded-bl-lg relative h-full">
        <div className="flex items-center p-3.5 gap-3">
          <img className="max-w-[75px] rounded-full" src={(user?.profilePic && user?.profilePic !== "") ? user?.profilePic : profilePic} alt="" />
          <div className="flex flex-col">
            <p className="text-3xl font-semibold">{user?.name}</p>
            <p className="text-sm font-light">{user?.designation}{user?.branch ? `,${user?.branch}` : ""}</p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-x-4 gap-y-12 w-3/4 p-3.5 input-container">
          {loading ?
            <Skeleton className="bg-cardBgColor h-[2.5rem] input-field mt-6" />
            :
            <div>
              <div className="font-bold text-primaryTextColor text-md">
                Name:
              </div>
              <div>
                <Input
                  className="h-[2.5rem] shadow-xl rounded-tr-lg input-field"
                  placeholder="Name"
                  name="name"
                  value={userInfo && userInfo.name}
                  handleOnChange={handleChange}
                />
              </div>
            </div>
          }

          {loading ?
            <Skeleton className="bg-cardBgColor h-[2.5rem] input-field mt-6" />
            :
            <div>
              <div className="font-bold text-primaryTextColor text-md">
                Email:
              </div>
              <div>
                <Input
                  className="h-[2.5rem] shadow-xl rounded-tr-lg input-field"
                  placeholder="Email"
                  name="email"
                  value={userInfo && userInfo.email}
                  handleOnChange={handleChange}
                />
              </div>
            </div>
          }

          {loading ?
            <Skeleton className="bg-cardBgColor h-[2.5rem] input-field mt-6" />
            :
            <div>
              <div className="font-bold text-primaryTextColor text-md">
                Phone number:
              </div>
              <div>
                <Input
                  className="h-[2.5rem] shadow-xl rounded-tr-lg input-field"
                  placeholder="Phone number"
                  value={userInfo && userInfo.phoneNumber}
                  name="phoneNumber"
                  handleOnChange={handleChange}
                />
              </div>
            </div>
          }

          {loading ?
            <Skeleton className="bg-cardBgColor h-[2.5rem] input-field mt-6" />
            :
            <div>
              <div className="font-bold text-primaryTextColor text-md">
                Date of birth:
              </div>
              <div>
                <input
                  type="date"
                  id="start-date"
                  className="calendar-input border p-2 w-full"
                  value={date && userInfo.dateOfBirth}
                  name="dateOfBirth"
                  onChange={handleChange}
                />
              </div>
            </div>
          }

          {loading ?
            <Skeleton className="bg-cardBgColor h-[2.5rem] input-field mt-6" />
            :
            <div>
              <div className="font-bold text-primaryTextColor text-md">
                Recovery email:
              </div>
              <div>
                <Input
                  className="h-[2.5rem] shadow-xl rounded-tr-lg input-field"
                  placeholder="Recovery Email"
                  name="alternateEmail"
                  value={userInfo && userInfo.alternateEmail}
                  handleOnChange={handleChange}
                />
              </div>
            </div>
          }
        </div>

        <div className="absolute bottom-0 right-0 m-10">
          <Button variant="primary" className="py-0 w-[8rem] rounded-3xl text-md" onClick={handleUpdateProfile}>
            {updateLoading ? <ButtonLoading /> : "Save Changes"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default Profile;
