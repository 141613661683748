import { createSlice } from "@reduxjs/toolkit"


export const firebaseTokenSlice = createSlice({
    name: 'firebase',
    initialState: {
        authToken: null,
    },
    reducers :{
        setToken : (state,action) =>{
            state.authToken = action.payload
        },  
    }
})

export const {setToken} = firebaseTokenSlice.actions
export default firebaseTokenSlice.reducer