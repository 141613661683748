import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getRoundApplicants = createAsyncThunk('getRoundApplicants', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/drive/drive-detail/rounds/applicants/${obj.driveId}/${obj.roundId}`, {
            params: {
                name: obj.name
            }
        })
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const roundApplicantsSlice = createSlice({
    name: 'Round Applicants',
    initialState: {
        loading: false,
        roundData: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getRoundApplicants.pending, (state, action) => {
            state.loading = true;
            state.roundData = [];
        })
        builder.addCase(getRoundApplicants.fulfilled, (state, action) => {
            state.loading = false;
            state.roundData = action.payload
        })
        builder.addCase(getRoundApplicants.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default roundApplicantsSlice.reducer