import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button"
import driveDoneIcon from '../../assets/driveDoneIcon.png'
import driveScheduledIcon from '../../assets/driveScheduledIcon.png'
import driveOngoingIcon from '../../assets/driveOngoingIcon.png'

const roundStatusEnum = {
    COMPLETED: 'Completed',
    ONGOING: "Ongoing",
    SCHEDULED: "Scheduled",
}
const RoundStatus = ({ roundDetails, controlledBy, driveId }) => {

    const navigate = useNavigate();
    return (
        <div className="">
            <h1 className="font-semibold text-2xl">Round Status</h1>
            <div className="flex items-center gap-x-6 mt-2">
                {
                    roundDetails.map((round, i) => {
                        return (
                            <div className="flex flex-col items-center space-y-3">
                                <div className={`w-40 h-40 flex items-center flex-col justify-center rounded-full border-8  ${round.status_name === "Completed" ? "border-primaryTextColor" : round.status_name === "Scheduled" ? "border-[#2B2A29]/70" : "border-[#C16D6F]"}`}>
                                    {
                                        round.status_name === roundStatusEnum.COMPLETED ?
                                            <div className="flex items-center gap-x-1">
                                                <img className="max-w-3" src={driveDoneIcon} alt="" />
                                                <p className="font-medium">Done</p>
                                            </div> : round.status_name === roundStatusEnum.ONGOING ?
                                                <div className="flex items-center gap-x-1">
                                                    <img className="max-w-3" src={driveOngoingIcon} alt="" />
                                                    <p className="text-[#C16D6F] font-medium">{round.status_name}</p>
                                                </div> :
                                                <div className="flex items-center gap-x-1">
                                                    <img className="max-w-3" src={driveScheduledIcon} alt="" />
                                                    <p className="text-[#2B2A29] font-medium">{round.status_name}</p>
                                                </div>
                                    }
                                    <p className="text-center">{round.round_name}</p>
                                </div>
                                <Button
                                    onClick={() => navigate(`rounds/${round.round_id}?round-name=${round.round_name}`)}
                                    variant="secondary"
                                    size="sm"
                                    className={`border shadow-md bg-white ${!(round.status_name === roundStatusEnum.COMPLETED || round.status_name === roundStatusEnum.ONGOING) ? 'invisible' : ''}`}
                                >
                                    View Results
                                </Button>

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default RoundStatus