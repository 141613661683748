import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../headersService";

export const fetchJobFormDetail = createAsyncThunk('fetchJobFormDetail', async (jobId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/job/${jobId}`);
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const jobFormDetailSlice = createSlice({
    name: "Job Form Detail",
    initialState: {
        loading: false,
        job: null,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchJobFormDetail.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchJobFormDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.job = action.payload
        })
        builder.addCase(fetchJobFormDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }

})

export default jobFormDetailSlice.reducer