import { fetchAllStudents } from "../../../src/redux/features/studentSlice";
import { getAllDriveDetails } from "../../../src/redux/features/getAllDriveDetailsSlice";
import { Button } from "../ui/button";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ReportCard = ({ title, count, total, percentage,yearFilter,actionType}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleKnowMoreClick = () => {
      // TO DO Later
      switch (actionType) {

        case "STUDENTS_REGISTERED":
          navigate('/student', { state: { yearFilter } });
          break;

        case "STUDENTS_PLACED": 
        navigate('/student', { state: { yearFilter,placed:"Placed" } });
        break;

        case "STUDENTS_REMAINING": 
          navigate('/student', { state: { yearFilter , placed: "Not Placed"} });
          break;
  
        case "COMPANIES_VISITED":
          navigate('/drive/on-campus-drive', {state:{nav_status: 'Completed' }} );
          break;
  
        case "INVITES_SENT":
          navigate('/companies',{state:{invite_sent: 'Approached' }} );
          break;
  
        case "INVITES_ACCEPTED":
          navigate('/companies',{state:{invite_accept: 'Accepted' }} );
          break;
  
        default:
          console.log('No action defined for this actionType');
      }
  };

  return (
    <div className="flex flex-col gap-y-3 border shadow-lg rounded-lg px-4 py-2">
      <h2 className="overflow-hidden break-words whitespace-normal text-[15px]">{title}</h2>
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-y-2">
          <p className="text-4xl text-primaryTextColor">{count}</p>
          <p className="min-h-[1.5rem]"> {(title !== "Companies Visited" && title !== "Students Registered" && title !== "Invites Sent") && `out of ${total}`}
          </p>
        </div>
        {
          percentage && <div className="w-1/3">
            <CircularProgressbar styles={buildStyles({
              pathColor: `var(--primary-color)`,
              textColor: 'var(--primary-color)',
            })} value={percentage} text={`${percentage}%`} />
          </div>
        }
      </div>
      <Button size="sm" variant="primary" onClick={handleKnowMoreClick}>
        Know More
      </Button>
    </div>
  );
};
export default ReportCard;
