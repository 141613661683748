import { Box, Button, TextField } from "@mui/material"
import { sendPasswordResetEmail } from "firebase/auth"
import { useState } from "react"
import { auth } from "../../config/firebaseConfig"
import { ToastContainer, toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const Forgot = () => {

    const [email, setEmail] = useState()
    const navigate = useNavigate()
    const handleForgotPass = async () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                toast.success(`Password reset Link has been sent to ${email}`, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                setTimeout(() => {
                    navigate('/login')
                }, 1000);
            })
            .catch((error) => {
                toast.error(error.message.substring(16), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
    }
    return (
        <div className="forgot_container">
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className="forgot_page">
                <Box display='flex' flexDirection='column' justifyContent='center'>
                    <TextField onChange={(e) => setEmail(e.target.value)} id="standard-basic" label="Enter your email to send password link" variant="standard" />
                    <Button sx={{marginTop : '10px'}} onClick={handleForgotPass}>Send Link</Button>
                </Box>
            </div>
        </div>
    )
}
export default Forgot