import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from '../../headersService'

export const addStudentsSheet = createAsyncThunk('addStudentsSheet', async (obj, { rejectWithValue }) => {
    try {
        const customConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: "blob"
        };
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/student/upload-student-data`, obj.body, customConfig)

        const href = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `created_students_sheet.xlsx`);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        console.log(rejectWithValue(error))
        return rejectWithValue(error)
    }
})

export const studentSheetSlice = createSlice({
    name: 'Add Students Sheet',
    initialState: {
        loading: false,
        successMessage: null,
        error: null
    },
    reducers: {
        resetStudentsAddSuccess: (state, action) => {
            state.successMessage = null
        },
        resetError: (state) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addStudentsSheet.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(addStudentsSheet.fulfilled, (state, action) => {
            state.loading = false;
            state.successMessage = "Download the file to view created students list along with their status"
        })
        builder.addCase(addStudentsSheet.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message || "Something went wrong"
        })
    }
})

export const { resetStudentsAddSuccess, resetError } = studentSheetSlice.actions
export default studentSheetSlice.reducer