import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getAllColleges = createAsyncThunk('getAllColleges', async (_,{rejectWithValue}) => {
    try {        
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/college`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const collegeSlice = createSlice({
    name: 'allColleges',
    initialState: {
        loading: false,
        colleges: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getAllColleges.pending,(state,action)=>{
            state.loading = true;
        })
        builder.addCase(getAllColleges.fulfilled,(state,action)=>{
            state.loading = false;
            state.allDriveDetails = action.payload
        })
        builder.addCase(getAllColleges.rejected,(state,action)=>{
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default collegeSlice.reducer