import CompanyLoading from "../Companies/CompanyLoading"
import { Skeleton } from "../ui/skeleton"

const RoundDetailsLoading = () => {
    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center gap-x-6">
                <Skeleton className="w-2/3 h-28" />
                <Skeleton className="w-1/3 h-28" />
            </div>
            <div className="my-3 flex items-center gap-x-3">
                <Skeleton className="w-20 h-10" />
                <Skeleton className="w-20 h-10" />
            </div>
            <CompanyLoading />
        </div>
    )
}
export default RoundDetailsLoading