import CustomImage from "../../CustomImage";

export const StudentColumns = [
    {
        accessorKey: "profilePic",
        header: "Photo",
        cell: ({ row }) => {
            const student = row.original;
            return <CustomImage className="w-12 h-12 object-contain rounded-full" src={student.profilePic} alt="student" section="student"   />
        }
    },
    {
        accessorKey: "name",
        header: "Name",
    },
    {
        accessorKey: "rollNo",
        header: "Roll No.",
    },
    {
        accessorKey: "branch",
        header: "Branch",
    },
    {
        accessorKey: "graduationYear",
        header: "Year",
    },
    {
        accessorKey: "gpa",
        header: "CGPA",
    },
    {
        accessorKey: "placementStatus",
        header: "Placement Status",
    },
    {
        accessorKey: "dreamPlacement",
        header: "Dream Placement",
        cell: ({ row }) => {
            const student = row.original
            return student.dreamPlacement ? <p>Yes</p> : <p>No</p>
        }
    },
    {
        accessorKey: "interested_in_placements",
        header: "Interested In Placements",
        cell: ({ row }) => {
            const student = row.original
            if(student.interestedInPlacements === null || student.interestedInPlacements === undefined) {
                return <p>N/A</p>
            }
            return student.interestedInPlacements ? <p>Yes</p> : <p>No</p>
        }
    },
    {
        accessorKey: "KTLive",
        header: "Live KT",
        
    },
    {
        accessorKey: "KTResolved",
        header: "Total KT",
    },
]