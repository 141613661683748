import { useState } from "react"
import { Button } from "../ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../ui/dialog"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { Textarea } from "../ui/textarea"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { createDriveAnnouncements } from "../../redux/features/drive-announcements/createAnnouncementSlice"
const CreateAnnouncementDialog = () => {

    const [open, setOpen] = useState(false)
    const { id } = useParams()
    const [announcementInfo, setAnnouncementInfo] = useState({
        title: "",
        subtitle: "",
        driveId: id
    })

    const [targetAudience, setTargetAudience] = useState("All Registered Students")
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setAnnouncementInfo({ ...announcementInfo, [e.target.name]: e.target.value })
    }
    const handleSendAnnouncement = () => {
        announcementInfo["target_audience"] = targetAudience
        dispatch(createDriveAnnouncements(announcementInfo))
        setOpen(false)
    }

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger>
                    <Button variant="primary" className="rounded-3xl">
                        + Create Announcement
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className="text-xl">Schedule Drive</DialogTitle>
                        <DialogDescription className="mt-3 flex flex-col space-y-3">
                            <div className='flex flex-col'>
                                <Label className="text-black mb-2 text-base" htmlFor="company-name">
                                    Select Target Audience
                                </Label>
                                <Select name='target_audience' onValueChange={(value) => setTargetAudience(value)}>
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Target audience" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="All Registered Students">
                                            All Registered Students
                                        </SelectItem>
                                        <SelectItem value="Current Round Students">
                                            Current Round Students
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className='flex flex-col'>
                                <Label htmlFor="title" className="text-black mb-2 text-base">
                                    Announcement Title
                                </Label>
                                <Input onChange={(e) => handleChange(e)} name="title" className="" placeholder="Announcement Title" />
                            </div>


                            <div className='flex flex-col gap-y-2'>
                                <Label htmlFor="note" className="text-black mb-2 text-base">
                                    Add Announcement text
                                </Label>
                                <Textarea onChange={(e) => handleChange(e)} name="subtitle" className="" rows={5} placeholder="Enter your text..." />
                            </div>
                        </DialogDescription>
                    </DialogHeader>

                    <DialogFooter>
                        <Button variant="primary" onClick={handleSendAnnouncement} className="rounded-3xl shadow-md" type="submit">Send Announcement</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </div>
    )
}
export default CreateAnnouncementDialog