import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DriveDetailsHeader from "./DriveDetailsHeader";
import { useDispatch, useSelector } from "react-redux";
import { getRoundApplicants } from "../../redux/features/getRoundApplicantsSlice";
import { useEffect, useRef } from "react";
import { Input } from "../ui/input";
import StudentTable from "../students/studentTable/StudentTable";
import { RoundStudentsColumns } from "./DriveTable/RoundStudentColumns";
import { getParicularDriveDetail } from "../../redux/features/getParticularDriveDetailSlice"
import RoundDetailsLoading from "./RoundDetailsLoading";
import { resetNextRoundDriveError, resetNextRoundDriveSuccess, startNextRound } from "../../redux/features/moveToNextRoundSlice";
import { Button } from "../ui/button";
import { resetStatusError, resetStatusSuccess } from "../../redux/features/updateStudentApplicationStatusSlice";
import { toast } from "sonner";
import { endDrive, resetEndDriveError, resetEndDriveSuccess } from "../../redux/features/endDriveSlice";
import CompanyLoading from "../Companies/CompanyLoading";
import { DataTable } from "../reusable/table/DataTable";

const DriveRoundDetails = () => {

    const { roundId, id: driveId } = useParams()
    const { user } = useSelector(state => state.loginReducer)
    const { particularDriveDetail: driveDetail, loading: driveLoading } = useSelector((state) => state.getParticularDriveDetails)
    const { success: updateStatusSuccess, error: updateStatusError, message, loading: updateStatusLoading } = useSelector((state) => state.updateStudentApplicationStatusReducer)
    const { success: nextRoundSuccess, loading: nextRoundLoading, message: nextRoundSuccessMsg, error: nextRoundError } = useSelector((state) => state.nextRoundReducer)
    const { success: endDriveSuccess, message: endDriveSuccessMsg, loading: endRoundLoading, error: endRoundError } = useSelector((state) => state.endDriveReducer)

    const [searchParams] = useSearchParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { roundData, loading } = useSelector((state) => state.roundApplicants)

    useEffect(() => {
        dispatch(getRoundApplicants({ roundId, driveId }));
        dispatch(getParicularDriveDetail({ id: driveId }))
    }, [updateStatusSuccess, nextRoundSuccess, endDriveSuccess, endRoundError])


    const handleStudentToNextRound = () => {
        dispatch(startNextRound(driveId))
    }

    const handleEndDrive = () => {
        dispatch(endDrive(driveId))
    }
    useEffect(() => {
        if (updateStatusSuccess) {
            toast.success(message)
            dispatch(resetStatusSuccess())
        }
        if (updateStatusError) {
            toast.error("Something went wrong")
            dispatch(resetStatusError())
        }
    }, [updateStatusSuccess, updateStatusError])

    useEffect(() => {
        if (nextRoundSuccess) {
            toast.success(nextRoundSuccessMsg)
            dispatch(resetNextRoundDriveSuccess())
        }
        if (nextRoundError) {
            toast.error(nextRoundError)
            dispatch(resetNextRoundDriveError())
        }
    }, [nextRoundSuccess, nextRoundError])

    useEffect(() => {
        if (endDriveSuccess) {
            toast.success(endDriveSuccessMsg)
            dispatch(resetEndDriveSuccess())
        }
        if (endRoundError) {
            toast.error(endRoundError)
            dispatch(resetEndDriveError())
        }
    }, [endDriveSuccess, endRoundError])

    const timeoutRef = useRef(null);

    const handleSearchChange = (e) => {
        const debounceDelay = 500;

        // Clear previous timeout if exists
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        // Set new timeout
        timeoutRef.current = setTimeout(() => {
            handleSearchChange.timeoutId = setTimeout(() => {
                dispatch(getRoundApplicants({ roundId, name: e.target.value, driveId }));
            }, debounceDelay);
        }, debounceDelay);

    }
    return (
        <div className="px-4 mt-3 flex flex-col space-y-4">
            {
                driveLoading | updateStatusLoading || nextRoundLoading || endRoundLoading ? <RoundDetailsLoading /> :
                    <>
                        <DriveDetailsHeader />
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center gap-x-2 ">
                                <h1 className="text-2xl font-bold min-w-1/2">{roundData.roundName}</h1>
                                <Input onChange={handleSearchChange} placeholder="Search Students" />
                            </div>
                            <div>
                                {
                                    driveDetail.isDriveCompleted ? <Button variant="primary">
                                        Drive Completed
                                    </Button> :
                                        driveDetail?.isLastRound ? <Button variant="outlined" onClick={handleEndDrive}>End Drive</Button> : <Button disabled={!driveDetail?.hasAcceptedStudents ||
                                            !driveDetail?.isDriveStarted} variant="outlined" onClick={handleStudentToNextRound}>Move Accepted Students to next Round</Button>
                                }
                            </div>
                        </div>
                        {
                            loading ? <CompanyLoading /> :
                                roundData.roundApplicants && <DataTable hasClick={true} hasPagination={false} onClickRoute={(row) => navigate(`/student/${row.studentId}`)} columns={RoundStudentsColumns} data={roundData.roundApplicants} />
                        }
                    </>
            }
        </div>
    )
}
export default DriveRoundDetails