import { BadgeCheck } from "lucide-react"
import { cn } from "../../lib/utils"
import { IoArrowBackCircle } from "react-icons/io5"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import interested from "../../assets/interested.png"
import { resetUpdateSuccess, updateStudentDetails } from "../../redux/features/studentDetailSlice"
import { toast } from "sonner"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { useEffect } from "react"
import CustomImage from "../CustomImage"

const StudentDetailsHeader = () => {

    const { studentDetail, updateSuccess } = useSelector((state) => state.getStudentDetail)
    const { loading, placedDetails } = useSelector((state) => state.studentPlacedDetails)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const placementOffers = placedDetails.filter((jobApplication) => jobApplication.ctc).map((record) => {
        return {
            ...record,
            appliedAt: new Date(record.appliedAt)
        };
    }).sort((a, b) => a.appliedAt - b.appliedAt);
    const placedAt = placementOffers[0];

    const handleValueChange = (value) => {
        dispatch(updateStudentDetails({ studentId: studentDetail.id, type: "Student", body: { isDreamPlacement: value } }))
    }

    useEffect(() => {
        if (updateSuccess) {
            toast.success("Status updated successfully")
            dispatch(resetUpdateSuccess())
        }
    }, [updateSuccess])

    return (
        <div className="grid grid-cols-3 space-x-6">
            <div className="bg-cardBgColor/35 relative col-span-2 py-4 px-6 shadow-lg rounded-lg border">
                <div onClick={() => navigate(-1)}>
                    <IoArrowBackCircle size={50} className="text-primaryTextColor cursor-pointer hover:text-primaryTextColor/80 absolute -left-5 -top-2" />
                </div>
                <div className="flex items-center gap-x-4 w-full ">
                    <div>
                        <CustomImage className="w-24 h-24 rounded-full object-contain" src={studentDetail?.profilePic} alt=""  section="student" />
                    </div>

                    <div className="flex flex-col space-y-1 flex-1">
                        <div className="flex items-center gap-x-3">
                            <h1 className="text-3xl font-semibold">{studentDetail?.name}</h1>
                            <div className="bg-white px-2 py-1 rounded-2xl shadow-lg">
                                {studentDetail?.branch}
                            </div>
                            <div className={cn(" rounded-2xl px-6 py-1 text-white shadow-lg", studentDetail.placementStatus != "NA" ? "bg-successColor" : "bg-red-500")}>
                                {studentDetail?.placementStatus}
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <div className="flex items-center gap-x-1">
                                <p className="text-[#8B4648] text-lg font-medium">Roll No : </p>
                                <p className="text-lg">{studentDetail?.rollNo}</p>
                            </div>
                            <div className="flex items-center gap-x-1">
                                <p className="text-[#8B4648] text-lg font-medium">Batch : </p>
                                <p className="text-lg">{studentDetail?.graduationYear}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    studentDetail?.interested_in_placements ?
                        <div className="absolute bottom-6 right-6 flex items-center gap-x-1">
                            <img className="max-w-5" src={interested} alt="" />
                            <p>Interested in Placements</p>
                        </div> : <div className="absolute bottom-6 right-6 flex items-center gap-x-1">
                            <img className="max-w-5" src={interested} alt="" />
                            <p>NA</p>
                        </div>
                }
            </div>

            <div className="shadow-lg rounded-lg border py-4 bg-white px-6 col-span-1">
                <div className="grid grid-rows-2 gap-y-2 h-full relative">
                    <div className="grid grid-cols-2 gap-x-4 h-1/2">
                        <div className="flex flex-col ">
                            <p className="text-primaryTextColor font-medium">Company of placement</p>
                            {
                                placedAt ?
                                    <p className="text-lg">{placedAt.company.name}</p> : <p>-</p>
                            }
                        </div>
                        <div className="flex flex-col ">
                            <p className="text-primaryTextColor font-medium">Job Role</p>
                            {
                                placedAt ?
                                    <p className="text-lg">{placedAt.driveName}</p> : <p>-</p>
                            }
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 h-1/2">
                        <div className="flex flex-col ">
                            <p className="text-primaryTextColor font-medium">CTC</p>
                            {
                                placedAt ?
                                    <p className="text-lg">{placedAt.ctc}</p> : <p>-</p>
                            }
                        </div>
                        <div className="flex flex-col">
                            <p className="text-primaryTextColor font-medium">Dream Placement</p>
                            <Select onValueChange={(value) => handleValueChange(value)} defaultValue={studentDetail.dreamPlacement}>
                                <SelectTrigger className="w-[100px]">
                                    <SelectValue placeholder="Select Dream Placement" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value={true}>Yes</SelectItem>
                                    <SelectItem value={false}>No</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default StudentDetailsHeader