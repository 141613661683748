import { Skeleton } from "../ui/skeleton"

const CompanyDetailLoading = () => {
    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center gap-x-6">
                <Skeleton className="w-2/3 h-28" />
                <Skeleton className="w-1/3 h-28" />
            </div>
            <div className="flex items-center gap-x-6">
                <Skeleton className="w-1/3 h-28" />
                <Skeleton className="w-2/3 h-28" />
            </div>
            <div className="flex flex-col items-center space-y-3">
                {
                    [1, 2, 3, 4, 5].map((item, i) => {
                        return <Skeleton key={i} className="h-10 w-full" />
                    })
                }
            </div>
        </div>
    )
}
export default CompanyDetailLoading