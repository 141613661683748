import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../headersService";

export const fetchJobFormResponses = createAsyncThunk('fetchJobFormResponses', async ({year}, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/job?year=${year}`);
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const jobFormSlice = createSlice({
    name: "Job Form",
    initialState: {
        loading: false,
        jobs: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchJobFormResponses.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchJobFormResponses.fulfilled, (state, action) => {
            state.loading = false;
            state.jobs = action.payload
        })
        builder.addCase(fetchJobFormResponses.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }

})

export default jobFormSlice.reducer