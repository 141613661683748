import axios from 'axios';
import { useSelector } from 'react-redux';
import { auth } from './config/firebaseConfig';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

const getHeaderToken = async () => {
    try {
        const user = auth.currentUser;
        if (user) {
            const token = await user.getIdToken();
            return token;
        } else {
            console.error("User not available");
            return null; // User is not signed in
        }
    } catch (error) {
        console.error("User not available", error);
        return null; // Error occurred while getting the token
    }
};
// Add a request interceptor
axiosInstance.interceptors.request.use(
    async (config) => {
        const token = await getHeaderToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default axiosInstance
