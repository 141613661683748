import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getAllInvitation = createAsyncThunk('getAllInvitation', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/connection?companyId=${obj.companyId || ''}&connectionStatus=${obj.connectionStatus || ''}`)
        return data

    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const allInvitationSlice = createSlice({
    name: 'Invitation',
    initialState: {
        loading: false,
        allInvitation: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getAllInvitation.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getAllInvitation.fulfilled, (state, action) => {
            state.loading = false;
            state.allInvitation = action.payload
        })
        builder.addCase(getAllInvitation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export default allInvitationSlice.reducer