import "../../styles/Login.css";
import { BsPeopleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Option = () => {
  return (
    <div className="option_container">
      <div className="option_card">

        <Link className="student" to={"/login"}>
          <div className="instituiton_login">
            <BsPeopleFill className="student_icon" />
            <h2>Institute Login</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default Option;
