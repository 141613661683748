import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchStudentDetails = createAsyncThunk('fetchStudentDetails', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/student/${obj.id}?type=${obj.type}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const updateStudentDetails = createAsyncThunk('updateStudentDetails', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.patch(`${process.env.REACT_APP_BACKEND_URL}/api/student/myprofile/update/${obj.studentId}?type=${obj.type}`, obj.body)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const studentDetailSlice = createSlice({
    name: 'StudentDetail',
    initialState: {
        loading: false,
        studentDetail: {},
        error: null,
        updateSuccess: false,
    },
    reducers: {
        resetUpdateSuccess: (state, action) => {
            state.updateSuccess = false
        },
        resetUpdateError: (state, action) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStudentDetails.pending, (state, action) => {
            state.loading = true;
            state.studentDetail = {}
        })
        builder.addCase(fetchStudentDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.studentDetail = action.payload
        })
        builder.addCase(fetchStudentDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
        builder.addCase(updateStudentDetails.pending, (state, action) => {
            state.loading = true;
            state.studentDetail = {}
        })
        builder.addCase(updateStudentDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.studentDetail = action.payload
            state.updateSuccess = true
        })
        builder.addCase(updateStudentDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export const { resetUpdateSuccess, resetUpdateError } = studentDetailSlice.actions
export default studentDetailSlice.reducer