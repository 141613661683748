import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const companyBranchAndLocation = createSlice({
    name: 'companyBranchAndLocation',
    initialState: {
        loading: false,
        selectedBranch: "",
        selectedLocation: "",
        error: null
    },
    reducers: {
        setSelectedBranch: (state, action) => {
            state.selectedBranch = action.payload
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload
        },
    }
})

export const { setSelectedBranch, setSelectedLocation } = companyBranchAndLocation.actions
export default companyBranchAndLocation.reducer