import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentDetails } from "../../redux/features/studentDetailSlice";
import { fetchStudentDocs } from "../../redux/features/getStudentDocsSlice";
import { getStudentPlacedDetais } from "../../redux/features/getStudentPlacementDetailsSlice";
import StudentDetailLoading from "./StudentDetailLoading";
import StudentDetailsHeader from "./StudentDetailsHeader";
import StudentInfo from "./StudentInfo";
import StudentDocuments from "./StudentDocuments";
import { cn } from "../../lib/utils";
const StudentProfile = () => {

  //fetching the id from the url of a particular student
  const { id } = useParams();

  //useselector to get the state of the student details
  const { loading } = useSelector((state) => state.getStudentDetail)

  const dispatch = useDispatch()

  useEffect(() => {
    //function call to fetch the student by the id
    dispatch(fetchStudentDetails({ id: id, type: 'Student' }))
    // dispatch(fetchStudentDocs(id))
    dispatch(getStudentPlacedDetais(id))
  }, [id]);

  const tabItems = [
    "Student Info",
    "Documents",
    "Coming Soon",
    "Coming Soon",
    // "Placement Stats",
  ]

  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="flex flex-col mt-3 px-4">
      {
        loading ? <StudentDetailLoading /> :
          <div>
            <StudentDetailsHeader />
            <div className="flex items-center mt-6">
              {
                tabItems.map((item, i) => {
                  return <div onClick={() => setActiveTab(i)} key={i} className={cn("px-6 w-1/10 bg-sidebarUnselectedBgColor cursor-pointer hover:bg-primaryTextColor transition-all border border-[#AE6C6F] text-white py-3", activeTab === i && "bg-primaryButtonColor", i === 0 && 'rounded-tl-lg', i === 4 && 'rounded-tr-lg')}>
                    <p className="font-medium">{item}</p>
                  </div>
                })
              }
            </div>
            <div className="rounded-lg shadow-lg px-8 py-6 border">
              {
                activeTab === 0 && <StudentInfo />
              }
              {
                activeTab === 1 && <StudentDocuments id={id}/>
              }
            </div>
          </div>
      }
    </div>
  );
};
export default StudentProfile;
