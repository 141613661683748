import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import { useDispatch, useSelector } from "react-redux";
import { fetchCorporateNetworkDetails } from "../../redux/features/fetchCorporateNetworkSlice";
import { handleSnackbar } from "../../redux/features/snackbarSlice";
import { deleteConnectionReq, resetConnectionDeleteSuccess } from "../../redux/features/removeInvitaionSlice";
import { Input } from "../ui/input";
import { Search } from "lucide-react";
import CompanyLoading from "./CompanyLoading";
import CorporateNetworkTable from "./CorporateNetworkTable/CorporateNetworkTable";
import { networkColumns } from "./CorporateNetworkTable/CorporateNetworkColumns";
import { Button } from "../ui/button";
import { DataTable } from "../reusable/table/DataTable";
const CorporateNetwork = () => {
  const { toggleOn } = useContext(Context);
  const [sort, setSort] = useState()
  const { user } = useSelector((state) => state.loginReducer)
  const { corporateNetworks, loading } = useSelector((state) => state.corporateNetwork)
  const { success, error } = useSelector((state) => state.deleteInvitation)
  const timeoutRef = useRef(null)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const handleClickOpen = (delId) => {
    setDialogOpen(true);
    setDeleteId(delId)
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleSort = (e) => {
    setSort(e.target.value)
    dispatch(fetchCorporateNetworkDetails({ sortBy: e.target.value }))
  }
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCorporateNetworkDetails())
  }, [success])


  const handleSuccess = () => {
    dispatch(handleSnackbar({ open: true, message: 'Connection Withdrawn successfully', type: 'success' }))
    dispatch(resetConnectionDeleteSuccess())
  }
  const handleError = () => {
    dispatch(handleSnackbar({ open: true, message: error, type: 'error' }))
    dispatch(resetConnectionDeleteSuccess())
  }

  const handleSearchChange = (e) => {
    // dispatch(updateSearchCompanyName(e.target.value))
    const debounceDelay = 500;

    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      handleSearchChange.timeoutId = setTimeout(() => {
        dispatch(fetchCorporateNetworkDetails({ name: e.target.value }))
      }, debounceDelay);
    }, debounceDelay);

  }
  useEffect(() => {
    if (success) {
      handleSuccess()
    }
    if (error) {
      handleError()
    }
  }, [success, error])

  const navigate = useNavigate()

  const handleDeleteConnection = (delId) => {
    dispatch(deleteConnectionReq(delId))
    setDialogOpen(false)
  }


  if (!user?.permission_manage_networks) {
    dispatch(handleSnackbar({ type: 'error', message: "You do not have permission to access this page", open: true }))
    return (<div display='flex' height={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <Button onClick={() => navigate(-1)} variant='contained'>Go Back</Button>
    </div>)
  }

  return (
    <div className="mt-3 w-full">
      <div className="flex flex-col gap-y-4">
        <div className="flex items-center space-x-4">
          <h1 className="font-bold text-2xl">Corporate Network</h1>
          <div className="flex bg-searchBgColor items-center justify-between border rounded-lg px-2 ">
            <Input onChange={handleSearchChange} className="font-light bg-transparent text-base border-none" placeholder="Search for Companies..." />
            <Search size={20} />
          </div>
        </div>

        {loading ? <CompanyLoading /> : corporateNetworks && <DataTable hasPagination={false} hasClick={false} data={corporateNetworks} columns={networkColumns} />}
      </div>
    </div>
  )
}

export default CorporateNetwork