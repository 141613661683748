import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

const CommonModal = ({ children, open, setOpen, title, triggerButton, width, className, submitFunction, footerButton }) => {
    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    {triggerButton}
                </DialogTrigger>
                <DialogContent className={className}>
                    <DialogHeader>
                        <DialogTitle>{title}</DialogTitle>
                    </DialogHeader>
                    {children}
                    <DialogFooter>
                        {footerButton}
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </div>
    )
}
export default CommonModal