import { Skeleton } from "../ui/skeleton"

const StudentDetailLoading = () => {
    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center gap-x-6">
                <Skeleton className="w-2/3 h-28" />
                <Skeleton className="w-1/3 h-28" />
            </div>
            <div className="flex items-center gap-x-2">
                {
                    [1, 2, 3].map((item, i) => {
                        return <Skeleton className="w-[200px] h-10" />
                    })
                }
            </div>
            <div className="flex items-center gap-x-6">
                <Skeleton className="w-1/3 h-40" />
                <Skeleton className="w-2/3 h-40" />
            </div>
        </div>
    )
}
export default StudentDetailLoading