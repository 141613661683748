import { Check, ChevronDown, EyeIcon, Trash, X } from "lucide-react"
import { Button } from "../ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "sonner"
import { useEffect } from "react"
import { resetConnectionUpdateError, resetConnectionUpdateSuccess, updateConnectionReq } from "../../redux/features/connectionUpdateSlice"

const statusEnum = {

    ACCEPTED: 'Accepted',
    REJECTED_BY_COLLEGE: "Rejected By College",

}
const CompanyViewInvitation = ({ connectionId }) => {

    const { success: updateStatusSuccess, error } = useSelector((state) => state.connectionUpdateReducer)
    const dispatch = useDispatch()
    const handleUpdateStatus = (status) => {
        dispatch(updateConnectionReq({ connectionId, connectionStatus: status }))
    }


    useEffect(() => {
        if (updateStatusSuccess) {
            toast.success("Invitation Accepted Successfully")
            dispatch(resetConnectionUpdateSuccess())
        } if (error) {
            toast.error("Something went wrong")
            dispatch(resetConnectionUpdateError())
        }
    }, [updateStatusSuccess, error])
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <Button variant="primary" size="md" className="shadow-md text-base">
                        View Invitation <ChevronDown size={10} />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => handleUpdateStatus(statusEnum.ACCEPTED)} className='flex items-center gap-x-2 cursor-pointer'  >
                        <Check size={16} />
                        Accept
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleUpdateStatus(statusEnum.REJECTED_BY_COLLEGE)} className='flex items-center gap-x-2 cursor-pointer'  >
                        <X size={16} />
                        Reject
                    </DropdownMenuItem>
                    <DropdownMenuItem className='flex items-center gap-x-2 cursor-pointer' >
                        <EyeIcon size={16} />
                        View
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}
export default CompanyViewInvitation