import React from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import CreateDriveModal from "./CreateDriveModal";

export const JobDetailHeader = ({data}) => {
    const navigate = useNavigate();
    return <div className="grid grid-cols-3 space-x-6">
            <div className="bg-cardBgColor/35 relative col-span-3 py-4 px-6 shadow-lg rounded-lg border">
                <div onClick={() => navigate(-1)}>
                    <IoArrowBackCircle size={50} className="text-primaryTextColor cursor-pointer hover:text-primaryTextColor/80 absolute -left-5 -top-2" />
                </div>
                <div className="flex items-center gap-x-4 w-full ">
                    <div className="flex space-y-1 flex-1 flex-row justify-between">
                        <div className="flex items-center gap-x-3 mx-6">
                            <h1 className="text-2xl font-semibold">{data.name_of_company}</h1>
                        </div>
                        <CreateDriveModal />
                    
                    </div>
                </div>
                
            </div>
            
        </div>
}