import { auth } from "../../config/firebaseConfig";
import React, { useState } from "react";
import { Separator } from "../ui/seperator";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { formToJSON } from "axios";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider, AuthErrorCodes } from "firebase/auth";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { formatDateWithTime } from "../../../src/utils/format-date";

const SignInAndSecurity = () => {
    const [changingPassword, setChangingPassword] = useState(false);
    const { createdAt, lastLoginAt } = auth.currentUser.toJSON();

    const changePassword = async (form) => {
        const data = formToJSON(form);
        try {
            await reauthenticateWithCredential(auth.currentUser,
                EmailAuthProvider.credential(auth.currentUser.email, data.oldPassword));
            try {
                await updatePassword(auth.currentUser, data.newPassword);
                toast.success("Password changed successfully");
                form.reset();
            } catch (error) {
                switch (error.code) {
                    case AuthErrorCodes.WEAK_PASSWORD:
                        form.elements.newPassword.setCustomValidity("Weak Password");
                        form.reportValidity();
                        break;
                }
            }
        } catch (error) {
            switch (error.code) {
                case AuthErrorCodes.INVALID_PASSWORD:
                    form.elements.oldPassword.setCustomValidity("Wrong password")
                    form.reportValidity();
                    break;
                case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
                    form.elements.oldPassword.setCustomValidity("Too many attempts try again later")
                    form.reportValidity();
                    break;
            }
        }
        setChangingPassword(false);
    }
    const onChangePassword = (evt) => {
        evt.preventDefault();
        setChangingPassword(true);
        const form = evt.target;
        form.elements.confirmNewPassword.setCustomValidity("");
        if (form.checkValidity()) {
            if (form.elements.newPassword.value !== form.elements.confirmNewPassword.value) {
                form.elements.confirmNewPassword.setCustomValidity("Should match the new password");
                form.reportValidity();
            } else {
                changePassword(form);
            }
        } else {
            form.reportValidity();
        }
    }

    return <div className="shadow-xl rounded-tr-lg rounded-br-lg rounded-bl-lg relative h-full gap-y-0">
        <div className="grid grid-cols-2 gap-x-4 gap-y-12 w-3/4 p-3.5 max-h-40">
            <div>
                <div className="font-bold text-primaryTextColor text-md">
                    Created at:
                </div>
                <div>
                    {formatDateWithTime(Number(createdAt))}
                </div>
            </div>
            <div>
                <div className="font-bold text-primaryTextColor text-md">
                    Last logged in at:
                </div>
                <div>
                    {formatDateWithTime(Number(lastLoginAt))}
                </div>
            </div>
        </div>
        <Separator />
        <div className="text-lg font-bold text-primaryTextColor text-md mt-3 mx-3">
            Change Password
        </div>
        <form onChange={(evt) => evt.target.setCustomValidity("")} className="grid grid-cols-3 gap-x-4 gap-y-0 w-3/4 p-3.5" onSubmit={onChangePassword}>
            <div>
                <div className="font-bold text-primaryTextColor text-md">
                    Enter Old Password:
                </div>
                <div>
                    <Input disabled={changingPassword} type="password" name="oldPassword" className="h-[2.5rem] shadow-xl rounded-tr-lg" required />
                </div>
            </div>
            <div>
                <div className="font-bold text-primaryTextColor text-md">
                    Enter New Password:
                </div>
                <div>
                    <Input disabled={changingPassword} type="password" name="newPassword" className="h-[2.5rem]  shadow-xl rounded-tr-lg" required />
                </div>
            </div>
            <div>
                <div className="font-bold text-primaryTextColor text-md">
                    Confirm New Password:
                </div>
                <div>
                    <Input disabled={changingPassword} type="password" name="confirmNewPassword" className="h-[2.5rem] shadow-xl rounded-tr-lg" required />
                </div>
            </div>
            <div className="mt-4">
                {
                    changingPassword ?
                        <Button disabled className="py-0 rounded-3xl text-md">
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Changing Password
                        </Button> :
                        <Button variant="primary" className="py-0 rounded-3xl text-md">
                            Change Password
                        </Button>
                }

            </div>
        </form>
    </div>

}
export default SignInAndSecurity