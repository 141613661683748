import { useDispatch, useSelector } from "react-redux";
import { fetchJobFormResponses } from "../redux/features/jobFormSlice";
import { useEffect, useState } from "react";
import CompanyLoading from "../components/Companies/CompanyLoading";
import JobTable from "../components/jobs/JobTable/JobTable";
import { JobFormColumns } from "../components/jobs/JobTable/JobFormColumns";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import SharingModal from "../components/SharingModal";
import { DataTable } from "../components/reusable/table/DataTable";
import { useNavigate } from "react-router-dom";

const JobsPage = () => {
  const dispatch = useDispatch();
  const { loading, jobs } = useSelector((state) => state.jobFormReducer);

  const [year, setYear] = useState(null);
  const navigate = useNavigate()
  const recentYears = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
    new Date().getFullYear() + 3,
    new Date().getFullYear() + 4,
  ];

  useEffect(() => {
    dispatch(fetchJobFormResponses({ year }));
  }, [year, dispatch]);


  const handleYearChange = (value) => {
    setYear(value);
  };

  return (
    <div>
      <div className="mt-3 flex justify-between">
        <h1 className="font-bold text-2xl">Job Form Responses</h1>
        <div className="flex items-center gap-x-3 flex-row gap-y-1">
          <Select
            onValueChange={handleYearChange}
            defaultValue={year}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Year" />
            </SelectTrigger>
            <SelectContent>
              {recentYears.map((item, i) => (
                <SelectItem key={i} value={item}>
                  {item}
                </SelectItem>
              ))}
              <SelectItem value={null}>None</SelectItem>
            </SelectContent>
          </Select>

          <SharingModal />
        </div>
      </div>

      <div className="mt-2">
        {loading ? (
          <CompanyLoading />
        ) : (
          jobs && <DataTable hasPagination={false} hasClick={true} onClickRoute={(row) => navigate(`/jobs/${row.id}`)} columns={JobFormColumns} data={jobs} />
        )}
      </div>
    </div>
  );
};

export default JobsPage;
