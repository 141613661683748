import { File, Mic, MicIcon, Upload } from "lucide-react"
import { Button } from "../ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../ui/dialog"
import { Textarea } from "../ui/textarea"
import { useEffect, useRef, useState } from "react"
import { set } from "date-fns"
import { useDispatch, useSelector } from "react-redux"
import { addConnectionReq, resetConnectionAddError, resetConnectionAddSuccess } from "../../redux/features/connectionAddSlice"
import { toast } from "sonner"

const CompanyInvitationModal = ({ companyId }) => {

    const [open, setOpen] = useState(false);
    const [inviteDetails, setInviteDetails] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const file1Ref = useRef(null);
    const file2Ref = useRef(null);
    const dispatch = useDispatch()
    const { success, error, loading } = useSelector((state) => state.addConnection)
    const { user } = useSelector((state) => state.loginReducer)

    const handleClose = () => {
        setOpen(false);
        setInviteDetails('')
        setSelectedFile(null)
        setSelectedAudio(null)
    };

    const uploadFile = (e) => {
        setSelectedFile(e.target.files[0])
    };

    const uploadAudio = (e) => {
        setSelectedAudio(e.target.files[0])
    };
    const sendInvite = (e) => {
        //checking if the description is empty
        const currentSelectedFile = selectedFile;

        const formData = new FormData();
        formData.append('file', currentSelectedFile)
        formData.append('connectionStatus', 'Approached')
        formData.append('companyId', companyId)
        // formData.append('collegeId', user?.institutionId)
        formData.append('description', inviteDetails)
        formData.append('label', 'company-invitation')

        if (!inviteDetails) {
            toast.warning("Please enter the invitation details")
            return
        }

        dispatch(addConnectionReq({ body: formData }))
        handleClose()
    };
    
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <Button onClick={(e) => {
                        setOpen(true)
                        e.stopPropagation();
                    }} variant="primary" size="md" className="shadow-md text-base bg-[#DA1F24] hover:bg-[#DA1F24]/70">
                        Invite
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Send your invitation</DialogTitle>
                        <DialogDescription>
                            <Textarea onChange={(e) => setInviteDetails(e.target.value)} className="mt-4 text-base text-black" rows={10} placeholder="Enter your invitation message..." />
                        </DialogDescription>
                    </DialogHeader>

                    <DialogFooter className="flex items-center sm:justify-between w-full">
                        <div className="space-x-4">

                            <input ref={file1Ref} type="file" id="file1" hidden onChange={uploadFile}></input>
                            {
                                selectedFile ? <Button className="py-1 rounded-2xl px-6" type="submit" variant="outlined" onClick={(e) => file1Ref.current.click()}>
                                    <File size={15} /> {selectedFile.name.substring(0, 15)}...
                                </Button> : <Button onClick={(e) => file1Ref.current.click()} variant="outlined" className="rounded-3xl" type="submit">
                                    <Upload size={15} className="mr-1" />
                                    Upload File
                                </Button>
                            }
                            <input ref={file2Ref} type="file" id="file2" hidden onChange={uploadAudio}></input>
                            {
                                selectedAudio ? <label onClick={(e) => file2Ref.current.click()}>
                                    <MicIcon /> {selectedAudio.name.substring(0, 15)}...
                                </label> : <Button onClick={(e) => file2Ref.current.click()} variant="outlined" className="rounded-3xl" type="submit">
                                    <Mic size={15} className="mr-1" />
                                    Upload Audio</Button>
                            }


                        </div>
                        <div>
                            <Button onClick={sendInvite} variant="primary" className="py-1 w-32 rounded-3xl" type="submit">Send Invite</Button>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>

    )
}
export default CompanyInvitationModal