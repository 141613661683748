import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError, HttpStatusCode } from "axios";
import { AuthErrorCodes, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { username } from '../../constants'
import axiosInstance from '../../headersService'

export const userLogin = createAsyncThunk('userLogin', async (userDetails, { rejectWithValue }) => {
    try {
        const credentials = await signInWithEmailAndPassword(auth, userDetails.email, userDetails.password)
        const { user: { email: signInEmail, accessToken, phoneNumber, uid, emailVerified } } = credentials

        // const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/student/myprofile/${uid}?type=TPO`)
        // const storedData = {
        //     email: signInEmail,
        //     token: accessToken,
        //     phoneNumber: phoneNumber,
        //     emailVerified: emailVerified,
        //     id: data.id,
        //     type: 'TPO',
        //     institutionId: institutionId,
        //     username: data.username
        // }
        // return storedData
        const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/tpo/my-profile`);
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const userSignup = createAsyncThunk('userSignup', async (userDetails, { rejectWithValue }) => {
    try {
        const response = await createUserWithEmailAndPassword(auth, userDetails.email, userDetails.password)
        const { user: { email: signInEmail, accessToken, phoneNumber, uid, emailVerified } } = response
        
        const storedData = {
            email: signInEmail,
            token: accessToken,
            phoneNumber: phoneNumber,
            emailVerified: emailVerified,
            id: uid,
            type: 'TPO',
            username: username,
            name: userDetails.name
        }
        
        sendEmailVerification(auth.currentUser)
            .then((response) => {
                return response
            }).catch((err) => {
                console.log(err.message)
                return rejectWithValue(err.message)
            });
        const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/api/student`, storedData)
        return userDetails.type
    } catch (error) {
        return rejectWithValue(error.message)
    }
})


export const loginSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        emailSent: false,
        loginSuccess: false,
        user: auth.currentUser,
        signUpType : 'self',
        error: null
    },
    reducers: {
        resetEmailSent: (state, action) => {
            state.emailSent = false
        },
        resetLoginSucess: (state, action) => {
            state.loginSuccess = false
        },
        resetLoginError: (state, action) => {
            state.error = null
        },
        logoutUser :(state,action) => {
            state.user = null
            state.loginSuccess = false
        },
        firebaseSetUser: (state, action) => {
            state.user = action.payload
            if(state.user != null) {
                state.loginSuccess = true;
            } else {
                state.loginSuccess = false;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.loading = false;
            state.loginSuccess = true
        })
        builder.addCase(userLogin.rejected, (state, action) => {
            state.loading = false;
            switch(action.payload.code) {
                case AuthErrorCodes.USER_DELETED:
                    state.error = "User does not exist"
                    break;
                case AuthErrorCodes.INVALID_EMAIL:
                    state.error = "User does not exist"
                    break;
                case AuthErrorCodes.INVALID_PASSWORD:
                    state.error ="Wrong password"
                    break;
                case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
                    state.error = "Too many failed login attempts. Login is temporarily blocked"
                    break;
                default:
                    state.error = action.payload.message;
                    if(action.payload instanceof AxiosError) {
                        state.error = action.payload.response.data.message;
                    }
            }
        })
        builder.addCase(userSignup.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(userSignup.fulfilled, (state, action) => {
            state.loading = false;
            state.emailSent = true
            state.error = null
            state.signUpType = action.payload
        })
        builder.addCase(userSignup.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export const { resetEmailSent,logoutUser, resetLoginSucess, resetLoginError, firebaseSetUser } = loginSlice.actions
export default loginSlice.reducer