import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../../headersService";


export const getDriveAnnouncements = createAsyncThunk('getDriveAnnouncements', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/drive-announcement`, {
            params: {
                driveId
            }
        })
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const getAnnouncementSlice = createSlice({
    name: 'Announcements',
    initialState: {
        loading: false,
        driveAnnouncements: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getDriveAnnouncements.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getDriveAnnouncements.fulfilled, (state, action) => {
            state.loading = false;
            state.driveAnnouncements = action.payload
        })
        builder.addCase(getDriveAnnouncements.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})
export default getAnnouncementSlice.reducer