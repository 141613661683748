import { useSelector, useDispatch } from "react-redux";
import { Badge } from "../ui/badge";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import axiosInstance from "../../headersService";
import { useEffect, useState } from "react";
import { Icon, Icons } from "../ui/icon";
import { toast } from "sonner";
import {
  updateDriveAnnouncement,
  withdrawDrive,
} from "../../redux/features/getParticularDriveDetailSlice";
import { formatDate } from "../../../src/utils/format-date";

const DriveDetailsHeader = ({ hideAnnouncementButton = false }) => {
  const {
    particularDriveDetail: driveDetail,
    loading,
    error,
  } = useSelector((state) => state.getParticularDriveDetails);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleWithdraw = () => {
    dispatch(withdrawDrive(driveDetail.id));
  };
  useEffect(() => {
    if (!loading && !error && driveDetail.status === "Withdrawn") {
      toast.success("Drive withdrawn successfully");
      navigate("/");
    } else if (!loading && error) {
      toast.error(error);
    }
  }, [loading, error, driveDetail.status, navigate]);

  return (
    <div className="grid grid-cols-3 space-x-6">
      <div className="bg-cardBgColor/35 relative col-span-2 py-4 px-6 shadow-lg rounded-lg border">
        <div onClick={() => navigate(-1)}>
          <IoArrowBackCircle
            size={50}
            className="text-primaryTextColor cursor-pointer hover:text-primaryTextColor/80 absolute -left-5 -top-2"
          />
        </div>
        <div className="flex items-center gap-x-4 w-full ">
          <div>
            <img
              className="w-20 h-20 rounded-full"
              src={
                driveDetail?.companyData?.logoUrl
                  ? driveDetail?.companyData?.logoUrl
                  : "/static/activeCompaniesIcon.png"
              }
              alt=""
            />
          </div>

          <div className="flex flex-col space-y-1 flex-1">
            <h1 className="text-2xl font-semibold">{driveDetail.drive_name}</h1>
            <div className="flex items-center gap-x-4">
              <div>
                <span className="text-brownSecondaryColor font-medium">Date of visit</span>{" "}
                : {formatDate(driveDetail.date_of_visit)}
              </div>
              <div>
                <span className="text-brownSecondaryColor font-medium">Drive Dates</span> :{" "}
                {formatDate(driveDetail?.start_date)} - {formatDate(driveDetail?.end_date)}
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="bg-dropdownBgColor rounded-lg font-medium px-4 py-1">
                {driveDetail.status}
              </div>
              {driveDetail.status === "Request Sent" && (
                <Button
                  className="bg-inviteSentButtonColor rounded-3xl mt-2 shadow-md text-white"
                  onClick={handleWithdraw}
                >
                  Withdraw Request
                </Button>
              )}
              {driveDetail.company_id ? (
                <></>
              ) : (
                <div className="flex items-center gap-x-4">
                  <DownloadStudentResumesButton drive={driveDetail} />
                  <DownloadStudentListButton drive={driveDetail} />
                </div>
              )}
            </div>
            {hideAnnouncementButton ? <></> :
              <div className="flex justify-end">
                <Button
                  onClick={() =>
                    navigate(
                      `/drive/drive-details/${driveDetail.id}/announcements`
                    )
                  }
                  className="bg-primaryButtonColor rounded-3xl mt-2 shadow-md text-white"
                >
                  Drive Announcements
                </Button>
              </div>}
          </div>
        </div>
      </div>
      <div className="shadow-lg rounded-lg border py-4 px-6">
        <h1 className="font-semibold text-primaryTextColor text-lg">
          Elgible Branches
        </h1>
        <div className="mt-2 flex flex-wrap gap-2">
          {driveDetail?.preffered_branches?.map((branch, i) => {
            return (
              <div
                key={i}
                className="bg-[#A0A0A0]/10 border rounded-2xl px-2 py-1 shadow-md text-black text-sm"
              >
                {branch}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default DriveDetailsHeader;

const DownloadStudentResumesButton = ({ drive }) => {
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (downloading) {
      getStudentZip().finally(() => setDownloading(false));
    }
  }, [downloading]);
  const getStudentZip = async () => {
    try{
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/drive/${drive.id}/download/resumes`,
      {
        responseType: "blob",
      }
    );
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `${encodeURIComponent(drive.drive_name)}_Resumes.zip`
    );
    document.body.appendChild(link);
    link.click();
    toast.success("Resume downloaded successfully!");
  }catch(error){
    if (error.response?.data instanceof Blob) {
      const errorText = await error.response.data.text();
      const errorMessage = JSON.parse(errorText)?.message || "No Records found";
      toast.error(errorMessage);
    } else {
      toast.error("Failed to download resumes. Please try again.");
    }
    console.error("Error downloading resumes:", error);    
  }
  };
  if (downloading) {
    return (
      <Button variant="outline" className="mr-1 rounded-3xl">
        Downloading Resumes
      </Button>
    );
  }
  return (
    <Button
      onClick={() => setDownloading(true)}
      variant="primary"
      className="mr-1 rounded-3xl"
    >
      <Icon icon={Icons.DOWNLOAD} className="bg-onPrimaryBg" />
      Download Resumes
    </Button>
  );
};

const DownloadStudentListButton = ({ drive }) => {
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (downloading) {
      getStudentExcel().finally(() => setDownloading(false));
    }
  }, [downloading]);
  const getStudentExcel = async () => {
    try{
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/drive/${drive.id}/download/excel`,
      {
        responseType: "blob",
      }
    );
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `${encodeURIComponent(drive.drive_name)}_StudentsList.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    toast.success("Student List downloaded successfully!");
  }catch(error){
    if (error.response?.data instanceof Blob) {
      const errorText = await error.response.data.text();
      const errorMessage = JSON.parse(errorText)?.message || "No Records found";
      toast.error(errorMessage);
    } else {
      toast.error("Failed to download resumes. Please try again.");
    }
    console.error("Error downloading resumes:", error);    
  }
  };

  if (downloading) {
    return (
      <Button variant="outline" className="rounded-3xl">
        Downloading Student List
      </Button>
    );
  }
  return (
    <Button
      onClick={() => setDownloading(true)}
      variant="primary"
      className="rounded-3xl text-white"
    >
      <Icon icon={Icons.DOWNLOAD} className="bg-onPrimaryBg" />
      Download Student List
    </Button>
  );
};
