import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStudents } from "../redux/features/studentSlice";
import { fetchAllYearBranch } from "../redux/features/allYearBranchSlice";
import { handleSnackbar } from "../redux/features/snackbarSlice";
import StudentList from "../components/students/StudentList";
import { getCollegeBranches } from "../redux/features/getCollegeBranch";
import { useLocation } from 'react-router-dom';

const StudentDirectory = () => {
  const { setSidebarLink } = useContext(Context);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.loginReducer)

  const { loading } = useSelector((state) => state.getAllStudents)
  const { limit, offset } = useSelector((state) => state.studentViewReducer)
  const location = useLocation();
  const { yearFilter,placed } = location.state || {};

  const dispatch = useDispatch()
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);


  if (!user?.permission_manage_users) {
    dispatch(handleSnackbar({ type: 'error', message: "You do not have permission to access this page", open: true }))
    return (<Box display='flex' height={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <Button onClick={() => navigate(-1)} variant='contained'>Go Back</Button>
    </Box>)
  }
  return (

    //starting of the student page

    <div className="overflow-hidden">
      <StudentList />
    </div>
  )
}
export default StudentDirectory;
