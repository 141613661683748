import React from 'react';
import { useImageFallback } from '../context/ImageContext';

const CustomImage = ({ src, alt, section, ...props }) => {
  const fallbacks = useImageFallback(); // This should now provide the `fallbacks` object
  const fallbackSrc = fallbacks.fallbacks[section] || fallbacks.fallbacks.default; // This should correctly resolve
  return (
    <img
      src={src || fallbackSrc} // Use fallback if src is not provided
      alt={alt}
      onError={(e) => {
        e.target.src = fallbackSrc; // Fallback on error
      }}
      {...props}
    />
  );
};

export default CustomImage;
