import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const fetchBranchWiseRegAndPlaced = createAsyncThunk('fetchBranchWiseRegAndPlaced', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/reports/branch-wise-reg-placed?year=${obj.year}`);
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const getBranchWiseRegAndPlaced = createSlice({
    name: 'get Reports',
    initialState: {
        loading: false,
        studentRegAndPlaced: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBranchWiseRegAndPlaced.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchBranchWiseRegAndPlaced.fulfilled, (state, action) => {
            state.loading = false;
            state.studentRegAndPlaced = action.payload
        })
        builder.addCase(fetchBranchWiseRegAndPlaced.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default getBranchWiseRegAndPlaced.reducer