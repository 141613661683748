import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

const StudentRegistered = () => {

    const { studentRegAndPlaced } = useSelector((state) => state.branchWiseRegAndPlaced)

    const chartData = {
        labels: studentRegAndPlaced?.map((student) => student?.branch),
        datasets: [
            {
                label: "Students registered",
                data: studentRegAndPlaced?.map((student) => student.registered),
                backgroundColor: ["#CBB6F8"],
                width: "20px",
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "No. of students registered",
                },
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Branch",
                },
            },
        },
    };
    return (
        <div>
            <div className="rounded-lg shadow-lg px-4 py-2">
                <h2 className="text-primaryTextColor text-lg font-semibold">Student Registered from each Branch</h2>
                <Bar data={chartData} options={options}></Bar>
            </div>
        </div>
    )
}
export default StudentRegistered