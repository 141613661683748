import { useSelector } from "react-redux";
import GraphModal from "./GraphModal";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";

const PlacementProgressGraph = () => {
    const { placementGraphDetails } = useSelector((state) => state.placementGraph)

    const chartData = {
        labels: Object.keys(placementGraphDetails),
        datasets: [
            {
                label: "Placed Students",
                data: Object.values(placementGraphDetails),
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Total Students Placed",
                },
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Months",
                },
            },
        },
    };
    return (
        <div className="rounded-lg shadow-lg px-4 py-2">
            <h2 className="text-primaryTextColor text-lg font-semibold">Placement Progress</h2>
            <Line data={chartData} options={options}>Graph</Line>
            {/* <GraphModal /> */}
        </div>
    )
}
export default PlacementProgressGraph