export const jobRolesColumns = [
    {
        accessorKey: "title",
        header: "Name",
    },
    {
        accessorKey: "ctc_max",
        header: "CTC",
        cell: ({ row }) => {
            const company = row.original;

            return <p>{company.ctc_min} - {company.ctc_max} LPA</p>
        }
    },
    {
        accessorKey: "job_type",
        header: "Job Type",
    },
]