import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux"

const CompaniesVisitedByBranch = () => {
    const { companyVisitedCountByBranch } = useSelector((state) => state.companyVisited)

    const chartData = {
        labels: companyVisitedCountByBranch?.map((company) => company?.branch?.split(' ')[0]),
        datasets: [
            {
                label: "Companies Visited",
                data: companyVisitedCountByBranch?.map((company) => company.count),
                backgroundColor: ["#CBB6F8"],
                width: "20px",
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "No. of Companies",
                },
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Branches",
                },
            },
        },
    };
    return (
        <div>
            <div className="rounded-lg shadow-lg px-4 py-2">
                <h2 className="text-primaryTextColor text-lg font-semibold">Companies visited for each branch</h2>
                <Bar data={chartData} options={options}></Bar>
            </div>
        </div>
    )
}
export default CompaniesVisitedByBranch