import { Button } from "../ui/button";
import { Skeleton } from "../ui/skeleton";

const UpcomingEvents = ({
  eventData,
  loading, 
  ...props
}) => {
 
  if(loading) {
    return  <div className=" rounded-2xl border shadow-lg px-4 py-6 row-span-2 col-span-1">
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-primaryTextColor text-xl">Upcoming events</h1>
      </div>
      <div className="flex flex-col space-y-2 mt-3">
        <div className="flex items-center gap-x-2">
                <Skeleton className="font-bold text-sm w-full h-6"/>
        </div>
        <div className="flex items-center gap-x-2">
                <Skeleton className="font-bold text-sm w-full h-6"/>
        </div>
        <div className="flex items-center gap-x-2">
                <Skeleton className="font-bold text-sm w-full h-6"/>
        </div>
      </div>
    </div>
  }
  const events = eventData.flatMap((drive) => [
    {name: `${drive.name} Starts`, date: drive.startDate},
    {name: `${drive.name} Campus Visit`, date: drive.driveDate},
    {name: `${drive.name} Ends`, date: drive.endDate},
  ]).filter((event) => event.date)
  .map(event => {
    return {
      name: event.name,
      date: new Date(event.date)
    }
  }).sort((a, b) => a.date - b.date);
  return (
    <div className="rounded-2xl border shadow-lg px-4 py-6 row-span-2 col-span-1 pb-12 h-[250px]">
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-primaryTextColor text-xl">Upcoming events</h1>
      </div>
      <div className="flex flex-col space-y-2 mt-3 h-full overflow-y-auto">
        { events.length > 0? events.map((event, i) => <div key={i} className="flex items-center gap-x-2">
                <p className="font-bold text-sm">{event.date.toLocaleDateString()}</p>
                <p className="font-bold text-sm">|</p>
                <p className="font-bold text-sm">{event.name}</p>
              </div>):<h2 className="w-full text-center">
                No Events
              </h2>}
      </div>
    </div>
  );
};
export default UpcomingEvents;
