import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../headersService";


export const fetchRecentlyConnectedCompanies = createAsyncThunk('fetchRecentlyConnectedCompanies', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/company/companies/recently_connected`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const recentlyConnectedSlice = createSlice({
    name: 'RecentlyConnected',
    initialState: {
        loading: false,
        recentlyConnected: {},
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRecentlyConnectedCompanies.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchRecentlyConnectedCompanies.fulfilled, (state, action) => {
            state.loading = false;
            state.recentlyConnected = action.payload
        })
        builder.addCase(fetchRecentlyConnectedCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default recentlyConnectedSlice.reducer