import { ReactNode, useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../ui/dialog"
import { Loader, Trash2, UploadCloud } from 'lucide-react';
import { Button } from '../ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { addStudentsSheet } from '../../redux/features/addStudentsSheet';
import { toast } from 'sonner';
import CommonSelect from '../reusable/dropdown/CommonSelect';

const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false)
    const { user } = useSelector((state) => state.loginReducer)
    const { loading, successMessage } = useSelector((state) => state.studentsSheetReducer)
    const { branch } = useSelector((state) => state.collegeBranchReducer)
    const [selectedBranch, setSelectedBranch] = useState("")
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileType = file.name.split('.').pop().toLowerCase();

            // Check if file is of type Excel
            if (fileType === 'xls' || fileType === 'xlsx') {
                setSelectedFile(file);
            } else {
                toast.warning("Please select an excel file")

            }
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            const fileType = file.name.split('.').pop().toLowerCase();

            // Check if file is of type Excel
            if (fileType === 'xls' || fileType === 'xlsx') {
                setSelectedFile(event.dataTransfer.files[0]);
            } else {
                toast.warning("Please select an excel file")
            }
        }
    };


    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleFileRemove = () => {
        setSelectedFile(null)
    }

    const handleUploadFiles = () => {

        if (!selectedFile) {
            return toast.warning("Please select a file to upload")
        }
        if (!selectedBranch) {
            return toast.warning("Please select branch of the students")
        }
        const formData = new FormData();
        formData.append('file', selectedFile)
        formData.append("branch", selectedBranch)

        dispatch(addStudentsSheet({ body: formData }))
    }

    useEffect(() => {
        if (successMessage) {
            setOpen(false)
            setSelectedFile(null)
        }
    }, [successMessage])

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger className='w-full'>
                    <Button variant="primary" className="rounded-3xl mt-4">
                        + Upload Students
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Upload Students file</DialogTitle>
                        {
                            selectedFile ?
                                <div className='bg-cardBgColor rounded-lg p-4 mt-8 flex w-full justify-between'>
                                    <p>{selectedFile.name}</p>
                                    <Trash2 onClick={handleFileRemove} size={20} className='cursor-pointer text-red-500' />
                                </div> : <div
                                    className="bg-cardBgColor rounded-lg p-4 mt-8 flex justify-between"
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <UploadCloud size={60} className='w-1/5 text-uploadFileIconColor' />
                                    <div className='flex w-4/5 items-center flex-col justify-center space-y-1'>
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            className="hidden"
                                            id="file-input"
                                        />
                                        <h1 className='font-semibold'>Drag and Drop files or <label className='text-primaryTextColor underline' htmlFor="file-input" >Browse</label></h1>
                                        <p className='text-uploadFileSecTextColor text-xs text-center font-light'>Supported formates: XLS,XLSX</p>
                                    </div>
                                </div>
                        }
                    </DialogHeader>
                    <DialogDescription>
                        <CommonSelect className={"w-full"} hideNone={true} handleSelectChange={(value) => setSelectedBranch(value)} items={branch.map((br) => ({ label: br, value: br }))} selectPlaceholder={"Select branch of students present inside excel file"} />
                    </DialogDescription>
                    <DialogFooter>
                        {
                            loading ? <Button disabled={loading} variant="primary" className="shadow-md rounded-3xl w-[120px]" type="submit">
                                <Loader className="animate-spin" />
                                Uploading
                            </Button> :
                                <Button onClick={() => handleUploadFiles()} type='submit' variant="primary" className="shadow-md rounded-3xl w-[100px]">
                                    Upload
                                </Button>
                        }
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </div>

    );
};

export default FileUpload;
