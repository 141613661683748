import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getStudentPlacedDetais = createAsyncThunk('getStudentPlacedDetais', async (studentId, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/student/placement/round-details/${studentId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const studentPlacedDetails = createSlice({
    name: 'studentPlacedDetails',
    initialState: {
        loading: false,
        placedDetails: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getStudentPlacedDetais.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getStudentPlacedDetais.fulfilled, (state, action) => {
            state.loading = false;
            state.placedDetails = action.payload
        })
        builder.addCase(getStudentPlacedDetais.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default studentPlacedDetails.reducer