import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from '../../headersService'


export const getCollegeBranches = createAsyncThunk('getCollegeBranches', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/api/college/branches`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const collegeBranchSlice = createSlice({
    name: 'collegeBranchSlice',
    initialState: {
        loading: false,
        branch: [],
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(getCollegeBranches.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getCollegeBranches.fulfilled, (state, action) => {
            state.loading = false;
            state.branch = action.payload
        })
        builder.addCase(getCollegeBranches.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default collegeBranchSlice.reducer