export const JobFormColumns = [
    {
        accessorKey: "companyLogo",
        header: "Logo",
        cell: ({ row }) => {
            const drive = row.original;
            return <img className="w-12 h-12 object-cover rounded-full" src={drive.companyLogo ? drive.companyLogo : "/static/activeCompaniesIcon.png"} alt="" />
        }
    },
    {
        accessorKey: "name_of_company",
        header: "Company Name",
    },
    {
        accessorKey: "sector",
        header: "Sector",
    },
    {
        accessorKey: "job_profile",
        header: "Job Profile",
    },
    {
        accessorKey: "designation",
        header: "Designation",
    },
    {
        accessorKey: "mode_of_recruitment",
        header: "Mode of Recruitment",
    },
    {
        accessorKey: "ctc_offered",
        header: "Ctc Offered",
    },
    {
        accessorKey: "contact_name",
        header: "Contact Name",
    },
]