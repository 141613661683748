
import React, { createContext, useContext } from 'react';

const ImageContext = createContext();

export const ImageProvider = ({ children, fallbacks }) => {
  return (
    <ImageContext.Provider value={{ fallbacks }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImageFallback = () => {
  return useContext(ImageContext);
};
