import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const Dashboard = () => {

    const { openMenu } = useSelector((state) => state.openMenu)
    return (
        <div className="flex px-8 py-8 overflow-x-hidden">
            <ToastContainer />
            <div className="mr-8">
                <Sidebar />
                <Navbar />
            </div>
            <div className={`${openMenu ? 'ml-[16%] w-5/6' : 'ml-[6%] w-full'} overflow-x-auto  mt-8 transition-all duration-700`}>
                <Outlet />
            </div>
        </div>
    )
}
export default Dashboard